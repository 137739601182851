import React, { Suspense, useState, useCallback, useEffect } from "react";
import { Progress } from "@nextui-org/react";
import { observer } from "mobx-react-lite";
import { OsynkLocalStore } from "locaLapps/Osynk/store/OsynkLocalStore";
import { TypewriterEffectSmooth } from "components/UI/typewriter-effect";
import { StarsBackground } from "components/UI/stars-background";
import Modal from "../Modal";

const World = React.lazy(() =>
  import("components/UI/globe").then((module) => ({ default: module.World }))
);

interface LoadingScreenProps {}

interface LoadingProps {
  localStore: OsynkLocalStore;
}

const LoadingScreen: React.FC<LoadingScreenProps> = () => {
  const words = [
    { text: "Initialization" },
    { text: "of" },
    { text: "your" },
    { text: "search" },
    { text: "..." },
  ];

  return (
    <div className="h-[100svh] w-full rounded-md flex flex-col items-center justify-center bg-black relative overflow-hidden">
      <div className="absolute inset-0 bg-customGray/30 z-0"></div>
      <div className="flex flex-col items-center">
        <div className="text-white text-2xl md:text-4xl font-bold">
          <span className="font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-synkColor/20 to-synkColor/70">
            <TypewriterEffectSmooth words={words} />
          </span>
        </div>
      </div>
    </div>
  );
};

interface GlobeContainerProps {
  onGlobeLoaded: () => void;
}

const GlobeContainer: React.FC<GlobeContainerProps> = React.memo(
  ({ onGlobeLoaded }) => {
    React.useEffect(() => {
      const timer = setTimeout(() => {
        onGlobeLoaded();
      }, 5500);
      return () => clearTimeout(timer);
    }, [onGlobeLoaded]);

    const globeConfig = React.useMemo(
      () => ({
        pointSize: 4,
        globeColor: "rgba(24,44,59,0.1)",
        showAtmosphere: true,
        atmosphereColor: "#22dcd1",
        atmosphereAltitude: 0.1,
        emissive: "rgba(24,44,59,0.8)",
        emissiveIntensity: 0.1,
        shininess: 0.9,
        polygonColor: "rgba(34,220,209,0.7)",
        ambientLight: "#38bdf8",
        directionalLeftLight: "#ffffff",
        directionalTopLight: "#ffffff",
        pointLight: "#ffffff",
        globeImageUrl: "/images/earth-night.jpg",
        arcTime: 1000,
        arcLength: 0.9,
        rings: 1,
        maxRings: 3,
        initialPosition: { lat: 22.3193, lng: 114.1694 },
        autoRotate: true,
        autoRotateSpeed: 0.8,
      }),
      []
    );

    const colors = ["#22dcd1", "#3b82f6", "#6366f1"];

    const sampleArcs = [
      {
        order: 1,
        startLat: -19.885592,
        startLng: -43.951191,
        endLat: -22.9068,
        endLng: -43.1729,
        arcAlt: 0.1,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 1,
        startLat: 28.6139,
        startLng: 77.209,
        endLat: 3.139,
        endLng: 101.6869,
        arcAlt: 0.2,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 1,
        startLat: -19.885592,
        startLng: -43.951191,
        endLat: -1.303396,
        endLng: 36.852443,
        arcAlt: 0.5,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 2,
        startLat: 1.3521,
        startLng: 103.8198,
        endLat: 35.6762,
        endLng: 139.6503,
        arcAlt: 0.2,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 2,
        startLat: 51.5072,
        startLng: -0.1276,
        endLat: 3.139,
        endLng: 101.6869,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 2,
        startLat: -15.785493,
        startLng: -47.909029,
        endLat: 36.162809,
        endLng: -115.119411,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 3,
        startLat: -33.8688,
        startLng: 151.2093,
        endLat: 22.3193,
        endLng: 114.1694,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 3,
        startLat: 21.3099,
        startLng: -157.8581,
        endLat: 40.7128,
        endLng: -74.006,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 3,
        startLat: -6.2088,
        startLng: 106.8456,
        endLat: 51.5072,
        endLng: -0.1276,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 4,
        startLat: 11.986597,
        startLng: 8.571831,
        endLat: -15.595412,
        endLng: -56.05918,
        arcAlt: 0.5,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 4,
        startLat: -34.6037,
        startLng: -58.3816,
        endLat: 22.3193,
        endLng: 114.1694,
        arcAlt: 0.7,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 4,
        startLat: 51.5072,
        startLng: -0.1276,
        endLat: 48.8566,
        endLng: -2.3522,
        arcAlt: 0.1,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 5,
        startLat: 14.5995,
        startLng: 120.9842,
        endLat: 51.5072,
        endLng: -0.1276,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 5,
        startLat: 1.3521,
        startLng: 103.8198,
        endLat: -33.8688,
        endLng: 151.2093,
        arcAlt: 0.2,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 5,
        startLat: 34.0522,
        startLng: -118.2437,
        endLat: 48.8566,
        endLng: -2.3522,
        arcAlt: 0.2,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 6,
        startLat: -15.432563,
        startLng: 28.315853,
        endLat: 1.094136,
        endLng: -63.34546,
        arcAlt: 0.7,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 6,
        startLat: 37.5665,
        startLng: 126.978,
        endLat: 35.6762,
        endLng: 139.6503,
        arcAlt: 0.1,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 6,
        startLat: 22.3193,
        startLng: 114.1694,
        endLat: 51.5072,
        endLng: -0.1276,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 7,
        startLat: -19.885592,
        startLng: -43.951191,
        endLat: -15.595412,
        endLng: -56.05918,
        arcAlt: 0.1,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 7,
        startLat: 48.8566,
        startLng: -2.3522,
        endLat: 52.52,
        endLng: 13.405,
        arcAlt: 0.1,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 7,
        startLat: 52.52,
        startLng: 13.405,
        endLat: 34.0522,
        endLng: -118.2437,
        arcAlt: 0.2,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 8,
        startLat: -8.833221,
        startLng: 13.264837,
        endLat: -33.936138,
        endLng: 18.436529,
        arcAlt: 0.2,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 8,
        startLat: 49.2827,
        startLng: -123.1207,
        endLat: 52.3676,
        endLng: 4.9041,
        arcAlt: 0.2,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 8,
        startLat: 1.3521,
        startLng: 103.8198,
        endLat: 40.7128,
        endLng: -74.006,
        arcAlt: 0.5,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 9,
        startLat: 51.5072,
        startLng: -0.1276,
        endLat: 34.0522,
        endLng: -118.2437,
        arcAlt: 0.2,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 9,
        startLat: 22.3193,
        startLng: 114.1694,
        endLat: -22.9068,
        endLng: -43.1729,
        arcAlt: 0.7,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 9,
        startLat: 1.3521,
        startLng: 103.8198,
        endLat: -34.6037,
        endLng: -58.3816,
        arcAlt: 0.5,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 10,
        startLat: -22.9068,
        startLng: -43.1729,
        endLat: 28.6139,
        endLng: 77.209,
        arcAlt: 0.7,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 10,
        startLat: 34.0522,
        startLng: -118.2437,
        endLat: 31.2304,
        endLng: 121.4737,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 10,
        startLat: -6.2088,
        startLng: 106.8456,
        endLat: 52.3676,
        endLng: 4.9041,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 11,
        startLat: 41.9028,
        startLng: 12.4964,
        endLat: 34.0522,
        endLng: -118.2437,
        arcAlt: 0.2,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 11,
        startLat: -6.2088,
        startLng: 106.8456,
        endLat: 31.2304,
        endLng: 121.4737,
        arcAlt: 0.2,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 11,
        startLat: 22.3193,
        startLng: 114.1694,
        endLat: 1.3521,
        endLng: 103.8198,
        arcAlt: 0.2,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 12,
        startLat: 34.0522,
        startLng: -118.2437,
        endLat: 37.7749,
        endLng: -122.4194,
        arcAlt: 0.1,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 12,
        startLat: 35.6762,
        startLng: 139.6503,
        endLat: 22.3193,
        endLng: 114.1694,
        arcAlt: 0.2,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 12,
        startLat: 22.3193,
        startLng: 114.1694,
        endLat: 34.0522,
        endLng: -118.2437,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 13,
        startLat: 52.52,
        startLng: 13.405,
        endLat: 22.3193,
        endLng: 114.1694,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 13,
        startLat: 11.986597,
        startLng: 8.571831,
        endLat: 35.6762,
        endLng: 139.6503,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 13,
        startLat: -22.9068,
        startLng: -43.1729,
        endLat: -34.6037,
        endLng: -58.3816,
        arcAlt: 0.1,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
      {
        order: 14,
        startLat: -33.936138,
        startLng: 18.436529,
        endLat: 21.395643,
        endLng: 39.883798,
        arcAlt: 0.3,
        color: colors[Math.floor(Math.random() * (colors.length - 1))],
      },
    ];

    return (
      <Suspense fallback={<div></div>}>
        <World data={sampleArcs} globeConfig={globeConfig} />
      </Suspense>
    );
  }
);

export const Loading: React.FC<LoadingProps> = observer(({ localStore }) => {
  const { progress, viewState, error } = localStore;
  const [isLoadingGlobe, setIsLoadingGlobe] = useState(true);
  const [showMainUI, setShowMainUI] = useState(false);

  const handleGlobeLoaded = useCallback(() => {
    setIsLoadingGlobe(false);
  }, []);

  const isLoading = viewState === "loading";

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMainUI(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center grow transform transition-all duration-700 scale-100">
      <div className="h-[100svh] w-full flex flex-col items-center justify-center bg-black relative overflow-hidden touch-none select-none">
        <div className="absolute inset-0 bg-customGray/30 z-0"></div>
        <div className="absolute inset-0 h-full w-full bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:130px_130px]"></div>

        <div
          className={`absolute w-full -bottom-[38%] h-[32rem] md:h-[110%] z-[3] transition-opacity duration-500 ${
            showMainUI && isLoading
              ? "opacity-100"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <GlobeContainer onGlobeLoaded={handleGlobeLoaded} />
        </div>

        {!showMainUI && isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black z-50">
            <LoadingScreen />
          </div>
        )}

        {showMainUI && isLoading && (
          <>
            <div className="absolute w-full top-0 flex flex-col w-full gap-3 mt-0 z-1">
              <Progress
                aria-label="Loading..."
                color="default"
                radius="none"
                classNames={{
                  indicator:
                    "bg-gradient-to-b from-synkColor/40 to-synkColor/70",
                }}
                value={progress}
              />
            </div>

            <div className="absolute w-full top-[20%] sm:top-[13%] md:top-[14%] lg:top-[15%] xl:top-[15%] flex flex-col w-full gap-3 mt-0 z-[1]">
              <div className="relative z-10">
                <div className="flex flex-col items-center">
                  <div className="text-white text-3xl md:text-6xl font-bold">
                    <span className="font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-synkColor/20 to-synkColor/70">
                      {progress}%
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="text-3xl md:text-5xl lg:text-6xl xl:text-8xl uppercase font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-synkColor/40 to-synkColor/80 py-1 max-w-[95%] md:max-w-xl lg:max-w-[95%] -mt-1 mx-auto">
                  Patience, the web <br /> reveals its secrets to us.
                </h2>
              </div>
            </div>
          </>
        )}

        <Modal
          classWrap="max-w-[28.5rem] rounded-3xl"
          showButtonClose
          visible={!!error}
          onClose={() => {
            localStore.error = null;
            localStore.setViewState("setup");
          }}
        >
          <div className="">
            <div className="mb-6 text-h4 text-gray-400">Error</div>
          </div>

          <div className="mb-4 p-8 bg-theme-on-surface border border-theme-stroke rounded-2xl md:px-4 flex gap-2 flex-col">
            <p className="text-h5 xl:pr-32 md:mb-0 md:pr-0 text-transparent bg-clip-text bg-gradient-to-br from-[#F871A0] to-[#F31260]">
              {error}
            </p>
          </div>
        </Modal>

        <StarsBackground />
      </div>
    </div>
  );
});
