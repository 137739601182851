import React from "react";
import clsx from "clsx";

interface WindowPreviewProps {
  title: string;
  children?: React.ReactNode;
  windowSize: { width: number; height: number };
  isLoading?: boolean;
  appLogoUrl?: string;
  component?: React.ComponentType<any>;
  localStore?: any;
}

const WindowPreview: React.FC<WindowPreviewProps> = ({
  title,
  children,
  windowSize,
  isLoading,
  appLogoUrl,
  component: AppComponent,
  localStore,
}) => {
  const windowClasses = clsx(
    "relative flex flex-col box-border",
    "bg-customGray/30 rounded-lg backdrop-blur",
    "cursor-default border border-lightColor/10 shadow-md"
  );

  const { width, height } = windowSize;

  return (
    <div
      className={windowClasses}
      style={{
        width,
        height,
      }}
    >
      <div className="flex-grow rounded-lg overflow-hidden w-full h-full pt-0 px-4 pb-4 box-border relative">
        {AppComponent ? (
          <AppComponent instanceId="preview" localStore={localStore} />
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default WindowPreview;
