import React, { useRef, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Image } from "@nextui-org/react";
import appStore from "stores/AppStore";
import { useOutsideClick } from "hook/useOutsideClick";
import { useCountdown } from "hook/useCountdown";

function isInstalled(appName: string) {
  return appStore.installedApps.some(
    (installed) => installed.appName === appName
  );
}

export interface AppDefinition {
  appName: string;
  title?: string;
  image?: string;
  screenshots?: string[];
  developer?: string;
  description?: string;
  category?: string;
  summary?: string;
  tags?: string[];
  notUninstallable?: boolean;
  releaseDate?: string;
}

interface Props {
  app: AppDefinition | null;
  onClose: () => void;
  instanceId?: string;
}

const ExpandedDetailModal: React.FC<Props> = ({ app, onClose, instanceId }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [downloadStatus, setDownloadStatus] = useState<
    "idle" | "downloading" | "completed"
  >("idle");
  const [mainImage, setMainImage] = useState<string>("");

  useOutsideClick(ref, onClose);

  const { days, hours, minutes, seconds, isReleased } = useCountdown(
    app?.releaseDate
  );

  useEffect(() => {
    if (app && app.image) {
      setMainImage(app.image);
    } else {
      setMainImage("");
    }
    if (app && isInstalled(app.appName)) {
      setDownloadStatus("completed");
    } else {
      setDownloadStatus("idle");
    }
  }, [app]);

  if (!app) {
    return null;
  }

  const isLocked = !isReleased;
  const prefix = instanceId ? `${instanceId}-` : "";

  if (isLocked) {
    return (
      <AnimatePresence>
        {app && (
          <>
            <motion.div
              className="absolute inset-0 z-40 bg-black/50 backdrop-blur-md"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
            <div className="fixed inset-0 z-50 flex items-center justify-center p-2 md:p-6">
              <motion.div
                ref={ref}
                key={app.appName}
                layoutId={`card-${prefix}${app.appName}`}
                className="relative flex flex-col items-center w-full max-w-md rounded-xl bg-customGray/30 backdrop-blur-2xl border border-neutral-700/50 shadow-2xl overflow-hidden p-6"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
              >
                <button
                  onClick={onClose}
                  title="Close"
                  className="absolute top-4 right-4 z-10 w-10 h-10 flex items-center justify-center rounded-full text-white hover:bg-red-700 transition focus:outline-none"
                >
                  ✕
                </button>
                <h2 className="text-2xl font-bold text-white mb-4">
                  Coming Soon
                </h2>
                <p className="text-sm text-neutral-400 mb-2">Release in :</p>
                <div className="text-xl text-white font-mono">
                  {days}d {hours}h {minutes}m {seconds}s
                </div>
              </motion.div>
            </div>
          </>
        )}
      </AnimatePresence>
    );
  }

  const handleDownload = () => {
    if (app.notUninstallable) return;
    setDownloadStatus("downloading");
    setTimeout(() => {
      appStore.installApp(app);
      setDownloadStatus("completed");
    }, 3000);
  };

  const handleUninstall = () => {
    if (app.notUninstallable) return;
    appStore.uninstallApp(app);
    setDownloadStatus("idle");
  };

  const isAppInstalled = isInstalled(app.appName);

  return (
    <AnimatePresence>
      {app && (
        <>
          <motion.div
            className="absolute inset-0 z-40 bg-black/50 backdrop-blur-md"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <div className="fixed inset-0 z-50 flex items-center justify-center p-2 md:p-6">
            <motion.div
              ref={ref}
              key={app.appName}
              layoutId={`card-${prefix}${app.appName}`}
              className="relative flex flex-col md:flex-row w-[90%] max-w-6xl h-[90%] rounded-xl bg-customGray/30 backdrop-blur-2xl border border-neutral-700/50 shadow-2xl overflow-hidden"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
            >
              <button
                onClick={onClose}
                title="Close"
                className="absolute top-4 right-4 z-10 w-10 h-10 flex items-center justify-center rounded-full text-white hover:bg-red-700 transition focus:outline-none"
              >
                ✕
              </button>
              <div className="flex-1 overflow-y-auto p-6 space-y-8">
                <motion.div layoutId={`image-${prefix}${app.appName}`}>
                  {mainImage && mainImage.trim() !== "" && (
                    <Image
                      src={mainImage}
                      alt={app.title || "No Title"}
                      removeWrapper
                      className="w-full h-auto rounded-xl bg-black/20"
                    />
                  )}
                </motion.div>
                {app.screenshots && app.screenshots.length > 0 && (
                  <div className="mt-2">
                    <hr className="border-neutral-700/50 w-16 mx-auto my-4" />
                    <div className="flex gap-3 flex-wrap items-center justify-center">
                      {app.screenshots.map((shot) => (
                        <button
                          key={shot}
                          onClick={() => setMainImage(shot)}
                          className="relative w-32 h-32 rounded-md overflow-hidden cursor-pointer transition border border-neutral-700/50 hover:shadow-md bg-customGray/30 hover:bg-customGray/50 hover:border-neutral-500 p-0"
                        >
                          <Image
                            src={shot}
                            alt={app.title || "App screenshot"}
                            removeWrapper
                            className="w-full h-full"
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                )}
                <hr className="border-neutral-700/50 w-16 mx-auto my-4" />
                <div className="space-y-2">
                  {app.title && app.title.trim() !== "" && (
                    <motion.h2
                      layoutId={`title-${prefix}${app.appName}`}
                      className="text-3xl font-bold text-neutral-100"
                    >
                      {app.title}
                    </motion.h2>
                  )}
                  {app.developer && app.developer.trim() !== "" && (
                    <motion.p
                      layoutId={`developer-${prefix}${app.appName}`}
                      className="text-sm text-neutral-400"
                    >
                      <span className="font-medium">{app.developer}</span>
                    </motion.p>
                  )}
                  {app.summary && app.summary.trim() !== "" && (
                    <motion.p
                      layoutId={`summary-${prefix}${app.appName}`}
                      className="text-neutral-200 leading-relaxed"
                    >
                      {app.summary}
                    </motion.p>
                  )}
                </div>
                {(app.description && app.description.trim() !== "") ||
                (app.tags && app.tags.length > 0) ? (
                  <>
                    <hr className="border-neutral-700/50 w-full mx-auto my-4" />
                    <div className="space-y-4">
                      <h3 className="text-xl text-neutral-100 font-semibold">
                        Details
                      </h3>
                      {app.description && app.description.trim() !== "" && (
                        <motion.p
                          layout
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="text-neutral-300 leading-relaxed"
                        >
                          {app.description}
                        </motion.p>
                      )}
                      {app.tags && app.tags.length > 0 && (
                        <div className="flex flex-wrap items-center gap-2">
                          <strong className="text-neutral-400">Tags:</strong>
                          {app.tags.map((tag) => (
                            <span
                              key={tag}
                              className="inline-block px-2 py-1 rounded bg-[#112233]/90 hover:bg-[#112233]/80 text-neutral-100 text-xs"
                            >
                              {tag}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
              <div className="w-full md:w-[360px] md:border-l border-t md:border-t-0 border-neutral-700/50 bg-white/10 backdrop-blur-md p-6 flex flex-col justify-between md:sticky md:top-0 shadow-xl">
                <div className="flex items-center gap-3 mb-6">
                  {app.image && app.image.trim() !== "" && (
                    <Image
                      src={app.image}
                      alt={app.title || "No Title"}
                      width={60}
                      height={60}
                      removeWrapper
                      className="rounded-lg"
                    />
                  )}
                  <div className="flex flex-col">
                    {app.title && app.title.trim() !== "" && (
                      <h4 className="text-lg font-semibold text-neutral-100">
                        {app.title}
                      </h4>
                    )}
                    {app.category && app.category.trim() !== "" && (
                      <span className="text-sm text-neutral-400">
                        {app.category}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex-1">
                  {app.description && app.description.trim() !== "" && (
                    <p className="text-sm text-neutral-300 leading-relaxed">
                      {app.description}
                    </p>
                  )}
                </div>
                <div className="mt-6 w-full">
                  {!app.notUninstallable && (
                    <>
                      {downloadStatus === "downloading" && (
                        <div className="relative w-full h-10 rounded-md bg-neutral-800 overflow-hidden">
                          <motion.div
                            initial={{ width: "0%" }}
                            animate={{ width: "100%" }}
                            transition={{ duration: 3 }}
                            className="absolute left-0 top-0 h-full bg-blue-500 rounded-md"
                          />
                        </div>
                      )}
                      {downloadStatus !== "downloading" && !isAppInstalled && (
                        <button
                          onClick={handleDownload}
                          className="w-full bg-white hover:bg-white/80 text-black font-semibold px-4 py-2 rounded-md shadow-sm transition"
                        >
                          Download
                        </button>
                      )}
                      {downloadStatus !== "downloading" && isAppInstalled && (
                        <button
                          onClick={handleUninstall}
                          className="w-full bg-red-600 hover:bg-red-700 text-white font-semibold px-4 py-2 rounded-md shadow-sm transition"
                        >
                          Uninstall
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  );
};

export default ExpandedDetailModal;
