import React from "react";
import { observer } from "mobx-react-lite";
import { OsynkLocalStore } from "locaLapps/Osynk/store/OsynkLocalStore";

import Setup from "./Setup";
import { Loading } from "./Loading";
import Conversation from "./Conversation";
import NoMore from "./NoMore/NoMore";

interface ChatIAProps {
  localStore: OsynkLocalStore;
}

const ChatIA: React.FC<ChatIAProps> = observer(({ localStore }) => {
  const { viewState } = localStore;

  return (
    <div className="relative bg-theme-n-8 overflow-hidden @container">
      {viewState === "setup" && <Setup localStore={localStore} />}
      {viewState === "loading" && <Loading localStore={localStore} />}
      {viewState === "chat" && <Conversation localStore={localStore} />}
      {viewState === "error" && <NoMore localStore={localStore} />}
    </div>
  );
});

export default ChatIA;
