import React from "react";
import WindowEditButtons from "./WindowEditButtons";
import userStore from "stores/UserStore";
import { Chip, Avatar } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

interface WindowTopBarProps {
  title: string;
  isMaximized: boolean;
  onClose: () => void;
  onMinimize?: () => void;
  onMaximize: () => void;
  onRestoreSize?: () => void;
  isMinimizable?: boolean;
  appLogoUrl?: string;
  vpnStatus?: string;
  isPreview?: boolean;
  requiresBackend?: boolean;
}

const WindowTopBar: React.FC<WindowTopBarProps> = ({
  title,
  isMaximized,
  onClose,
  onMinimize,
  onMaximize,
  onRestoreSize,
  isMinimizable,
  appLogoUrl,
  vpnStatus,
  isPreview,
  requiresBackend,
}) => {
  const { t } = useTranslation();
  const vpnObj = userStore.availableVPNStatuses.find(
    (x) => x.key === vpnStatus
  );
  const vpnLogoUrl = vpnObj?.src;
  const vpnLabel = vpnObj ? t(vpnObj.translateKey) : "";

  return (
    <div className="py-1.5 px-3 text-white w-full rounded-t-lg flex items-center justify-between relative window-title-bar">
      <div className="flex items-center gap-2">
        {appLogoUrl && (
          <img src={appLogoUrl} alt={title} className="h-5 w-5 rounded-xl" />
        )}
        <span className="font-semibold">{title}</span>
      </div>
      {requiresBackend && vpnStatus && vpnStatus !== "none" && vpnLogoUrl && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <Chip
            variant="flat"
            color="success"
            avatar={<Avatar src={vpnLogoUrl} alt="VPN" />}
          >
            {vpnLabel}
          </Chip>
        </div>
      )}

      {!isPreview && (
        <WindowEditButtons
          onMinimize={onMinimize}
          onMaximize={onMaximize}
          onClose={onClose}
          isMaximized={isMaximized}
          isMinimizable={isMinimizable}
          onRestoreSize={onRestoreSize}
        />
      )}
    </div>
  );
};

export default WindowTopBar;
