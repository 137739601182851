import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { HeroHighlight } from "components/UI/hero-highlight";
import { Spotlight } from "components/UI/Spotlight";
import Select from "pages/SynkINT/components/ui/Select";
import Modal from "../Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { OsynkLocalStore } from "locaLapps/Osynk/store/OsynkLocalStore";

const categories = [
  {
    id: "email",
    title: "Email",
    placeholder: "example@email.com",
    example: "johndoe@example.com",
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    errorMessage: "Please enter a valid email address.",
  },
  {
    id: "phone",
    title: "Phone",
    placeholder: "+1 555-123-4567",
    example: "+1 555-123-4567",
    regex: null,
    errorMessage: "Please enter a valid phone number.",
  },
  {
    id: "example",
    title: "Example",
    placeholder: "cpzhao@gmail.com",
    example: "cpzhao@gmail.com",
  },
];

interface SetupProps {
  localStore: OsynkLocalStore;
}

const Setup: React.FC<SetupProps> = observer(({ localStore }) => {
  const selectedCategory =
    categories.find((cat) => cat.id === localStore.selectedSetupCategory) ||
    categories[0];

  const { setupInputValue } = localStore;

  const [visibleErrorModal, setVisibleErrorModal] = React.useState(false);
  const [visibleHelpModal, setVisibleHelpModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { id, regex, errorMessage: categoryErrorMessage } = selectedCategory;

    if (regex && !regex.test(setupInputValue.trim())) {
      setErrorMessage(categoryErrorMessage || "Invalid input.");
      setVisibleErrorModal(true);
      return;
    }

    if (id === "phone") {
      if (!setupInputValue || setupInputValue.length < 5) {
        setErrorMessage(
          categoryErrorMessage || "Please enter a valid phone number."
        );
        setVisibleErrorModal(true);
        return;
      }
    }

    if (id === "example") {
      localStore.startScraping("email", "cpzhao@gmail.com");
      return;
    }

    localStore.startScraping(id, setupInputValue);
  };

  const handleCategoryChange = (newCategory: any) => {
    localStore.setSelectedSetupCategory(newCategory.id);

    if (newCategory.id === "example") {
      localStore.setSetupInputValue("cpzhao@gmail.com");
    } else {
      localStore.setSetupInputValue("");
    }
  };

  return (
    <div className="h-full w-full relative bg-black/[0.96] overflow-hidden">
      <div className="absolute inset-0 bg-customGray/30 z-0"></div>
      <div className="absolute inset-0 h-full w-full bg-[linear-gradient(to_right,#80808010_1px,transparent_1px),linear-gradient(to_bottom,#80808010_1px,transparent_1px)] bg-[size:150px_150px]"></div>

      <HeroHighlight>
        <div className="relative flex flex-col justify-center items-center h-screen w-full mt-[-100px] @sm:mt-0">
          <div className="@sm:bottom-[unset] absolute z-10">
            <p className="text-center text-[6rem] @sm:text-[10rem] @md:text-[12rem] @lg:text-[17rem] select-none font-bold bg-clip-text text-transparent bg-gradient-to-b from-synkColor/10 to-synkColor/50 inset-x-0">
              OSYNK
            </p>
          </div>

          <div className="block z-[60] sm:hidden relative inline-flex shrink-0 mb-[20px]">
            <Select
              className="min-w-[6.5rem] @sm:min-w-[7.5rem] @md:min-w-[8.5rem] z-0 items-center justify-center box-border appearance-none subpixel-antialiased rounded-large !gap-0 transition-transform-colors-opacity motion-reduce:transition-none bg-default/40 text-default-foreground h-12 rounded-l-2xl select-none"
              value={selectedCategory}
              onChange={handleCategoryChange}
              items={categories}
            />
          </div>

          <div className="relative z-20 max-w-[90%] @sm:max-w-[400px] @md:max-w-[550px] @lg:max-w-[1150px] w-full flex flex-col lg:flex-row gap-3 justify-center items-center">
            <div className="flex flex-col justify-start max-w-[750px] w-full">
              <div className="max-w-[750px] flex flex-col justify-start gap-2 relative w-full p-2 rounded-3xl border border-gray-200 dark:border-gray-800">
                <div className="absolute inset-0 overflow-hidden rounded-3xl border-none">
                  <Spotlight className="!w-[1000px] !h-[900px] !left-[245px]" />
                </div>

                <div className="flex justify-start gap-2 w-full overflow-visible">
                  <div className="hidden sm:block relative inline-flex shrink-0">
                    <Select
                      className="min-w-[6.5rem] @sm:min-w-[7.5rem] @md:min-w-[8.5rem] z-0 items-center justify-center box-border appearance-none subpixel-antialiased rounded-large !gap-0 transition-transform-colors-opacity motion-reduce:transition-none bg-default/40 text-default-foreground h-12 rounded-l-2xl"
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      items={categories}
                    />
                  </div>

                  <form
                    onSubmit={handleSubmit}
                    className="w-full relative flex justify-between items-center py-3 px-1 bg-white dark:bg-zinc-800 rounded-2xl shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),_0px_1px_0px_0px_rgba(25,28,33,0.02),_0px_0px_0px_1px_rgba(25,28,33,0.08)] transition duration-1000"
                  >
                    {selectedCategory.id === "phone" ? (
                      <div className="w-full pl-3 pr-20 flex items-center">
                        <PhoneInput
                          country={"us"}
                          value={setupInputValue}
                          onChange={(phone) =>
                            localStore.setSetupInputValue(phone)
                          }
                          inputClass="!bg-transparent !text-sm !text-black dark:!text-white !border-none !outline-none focus:!outline-none !shadow-none placeholder:text-theme-tertiary !h-[24px]"
                          containerClass="!w-full"
                          buttonClass="!bg-transparent !border-none"
                          dropdownClass="!border-2 !border-theme-stroke !bg-theme-on-surface-1 !rounded-xl !outline-none"
                          enableAreaCodes={true}
                          enableTerritories={false}
                        />
                      </div>
                    ) : (
                      <input
                        required
                        placeholder={selectedCategory.placeholder}
                        value={setupInputValue}
                        onChange={(e) =>
                          localStore.setSetupInputValue(e.target.value)
                        }
                        className="w-full relative text-sm @sm:text-base scrollbar-none whitespace-pre-line resize-none z-50 border-none dark:text-white bg-transparent text-black h-full rounded-xl focus:outline-none focus:ring-0 pl-3 pr-20 h-[24px]"
                      />
                    )}

                    <button
                      type="submit"
                      className="absolute right-2 top-1/2 z-50 -translate-y-1/2 h-8 w-8 rounded-full bg-black dark:bg-zinc-900 transition duration-200 flex items-center justify-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="text-gray-100 h-4 w-4"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 12l14 0"></path>
                        <path d="M13 18l6 -6"></path>
                        <path d="M13 6l6 6"></path>
                      </svg>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 z-0 w-screen h-[96px] flex-col items-center justify-center space-x-4 px-5 pb-5 flex">
          <div className="mx-auto text-center @md:max-w-[58rem]">
            <div className="flex w-full flex-wrap items-center justify-center gap-2 pt-5 text-xs font-medium text-gray-600 dark:text-gray-400">
              <button
                onClick={() => setVisibleHelpModal(true)}
                className="hover:underline focus:outline-none"
              >
                Help
              </button>
              •
              <a href="#" className="hover:underline">
                Privacy
              </a>
              •
              <a href="#" className="hover:underline">
                Documentation
              </a>
            </div>
            <p className="mt-3 text-xs leading-7 text-gray-500">
              OSYNK - Maximize OSINT techniques using multi-agent
            </p>
          </div>
        </div>
      </HeroHighlight>

      <Modal
        classWrap="max-w-[28.5rem] rounded-3xl"
        showButtonClose
        visible={visibleErrorModal}
        onClose={() => setVisibleErrorModal(false)}
      >
        <div className="">
          <div className="mb-6 text-h4 text-gray-400">Validation Error</div>
        </div>

        <div className="mb-4 p-8 bg-theme-on-surface border border-theme-stroke rounded-2xl @md:px-4 flex gap-2 flex-col">
          <p className="text-h5 @xl:pr-32 @md:mb-0 @md:pr-0 text-transparent bg-clip-text bg-gradient-to-br from-[#F871A0] to-[#F31260]">
            {errorMessage}
          </p>
          {selectedCategory.example && (
            <p className="text-sm text-gray-300">
              Example: {selectedCategory.example}
            </p>
          )}
        </div>
      </Modal>

      <Modal
        classWrap="max-w-[28.5rem] rounded-3xl"
        showButtonClose
        visible={visibleHelpModal}
        onClose={() => setVisibleHelpModal(false)}
      >
        <div className="">
          <div className="mb-6 text-h4 text-gray-400">Help</div>
        </div>

        <div className="mb-4 p-8 bg-theme-on-surface border border-theme-stroke rounded-2xl md:px-4 flex gap-2 flex-col">
          <p className="text-h5 xl:pr-32 md:mb-0 md:pr-0 text-transparent bg-clip-text bg-gradient-to-br from-gray-100 to-slate-700">
            OSYNK is an advanced OSINT tool integrated with artificial
            intelligence to enhance your data analysis capabilities. It allows
            you to scrape and analyze data from various sources, providing
            insightful results powered by AI-driven explanations and summaries.
          </p>
        </div>
      </Modal>
    </div>
  );
});

export default Setup;
