import React from "react";

interface SynkWSProps {
  instanceId?: string;
  localStore?: any;
}

const SynkWS: React.FC<SynkWSProps> = () => {
  return (
    <div className="w-full h-full">
      <iframe
        src="https://synk.ws"
        title="Synk.WS Iframe"
        className="w-full h-full border-0"
      />
    </div>
  );
};

export default SynkWS;
