import React, { useState, useEffect } from "react";
import { Button } from "@nextui-org/react";
import { Clock } from "lucide-react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Calendar,
} from "@nextui-org/react";
import { today, getLocalTimeZone, CalendarDate } from "@internationalized/date";
import { I18nProvider } from "@react-aria/i18n";
import userStore from "stores/UserStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

const BtnDate: React.FC = observer(() => {
  const { t } = useTranslation();

  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  const [selectedDate, setSelectedDate] = useState<CalendarDate>(
    today(getLocalTimeZone())
  );

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const locale = userStore.currentLanguage;

      const timeOptions: Intl.DateTimeFormatOptions = {
        hour: "2-digit",
        minute: "2-digit",
      };

      const dateOptions: Intl.DateTimeFormatOptions = {
        weekday: "short",
        day: "2-digit",
        month: "short",
      };

      const timeStr = now.toLocaleString(locale, timeOptions);
      const dateStr = now.toLocaleString(locale, dateOptions);

      setCurrentTime(timeStr);
      setCurrentDate(dateStr);
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 60000);
    return () => clearInterval(intervalId);
  }, [userStore.currentLanguage]);

  const handleDateChange = (newDate: CalendarDate) => {
    setSelectedDate(newDate);
  };

  const toggleShowDate = () => {
    userStore.toggleShowDate();
  };

  return (
    <I18nProvider locale={userStore.currentLanguage}>
      <div className="flex items-center mt-4">
        {userStore.showDate ? (
          <Dropdown
            closeOnSelect={false}
            disableAnimation
            classNames={{
              base: "before:shadow-custom",
              content:
                "overflow-auto max-h-[80vh] p-0 bg-customGray/60 backdrop-blur-dock border-small border-divider shadow-custom relative",
            }}
            placement="bottom-start"
          >
            <DropdownTrigger>
              <Button
                aria-label={t("open_calendar")}
                className="h-[50px] bg-transparent border-none opacity-60 hover:bg-white/20"
              >
                <div className="flex flex-col gap-0 items-start leading-tight p-1">
                  <span className="text-xs font-medium bg-clip-text animated-heading text-transparent bg-gradient-to-br from-neutral-700 via-white to-white">
                    {currentDate}
                  </span>
                  <span className="max-w-5xl mx-auto text-center tracking-tight font-black bg-clip-text animated-heading text-transparent bg-gradient-to-br from-neutral-700 via-white to-white text-2xl leading-none">
                    {currentTime}
                  </span>
                </div>
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label={t("open_calendar")}
              variant="flat"
              itemClasses={{
                base: ["data-[hover=true]:bg-custom2"],
              }}
            >
              <DropdownItem key="calendar" textValue="Date Picker">
                <Calendar
                  aria-label="Date Picker"
                  showMonthAndYearPickers
                  value={selectedDate}
                  color="success"
                  onChange={handleDateChange}
                  showShadow={false}
                  className="border-none shadow-none bg-transparent"
                  classNames={{
                    headerWrapper: ["bg-transparent"],
                    gridHeader: ["bg-transparent"],
                    header: ["bg-lightColor/15"],
                  }}
                />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        ) : (
          <Button
            variant="faded"
            className="border-none opacity-60"
            data-magnetic
            isIconOnly
            onClick={toggleShowDate}
            aria-label={t("show_date")}
          >
            <Clock className="h-4 w-4" />
          </Button>
        )}
      </div>
    </I18nProvider>
  );
});

export default BtnDate;
