import React from "react";
import ExpandableCardItem from "./ExpandableCardItem";

interface Props {
  apps: any[];
  onSelect: (app: any) => void;
  instanceId?: string;
}

const ExpandableCardList: React.FC<Props> = ({
  apps,
  onSelect,
  instanceId,
}) => {
  return (
    <div className="grid grid-cols-1 @sm:grid-cols-2 @md:grid-cols-3 @lg:grid-cols-4 @xl:grid-cols-4 @2xl:grid-cols-4 gap-4 p-4">
      {apps.map((app) => (
        <ExpandableCardItem
          key={app.appName}
          app={app}
          onClick={onSelect}
          instanceId={instanceId}
        />
      ))}
    </div>
  );
};

export default ExpandableCardList;
