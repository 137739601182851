import React from "react";
import { observer } from "mobx-react-lite";
import userStore from "stores/UserStore";
import { useTranslation } from "react-i18next";
import { BACKGROUNDS, isImage, isVideo } from "data/background";
import { Card, CardBody, CardFooter, Image } from "@nextui-org/react";

const BackgroundSelector: React.FC = observer(() => {
  const { t } = useTranslation();

  const changeBackground = (url: string) => {
    userStore.setBackground(url);
  };

  return (
    <div className="flex flex-col items-center w-full h-full p-4">
      <div className="flex flex-col items-center w-full h-full max-w-[1250px]">
        <div className="gap-4 grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] w-full">
          {BACKGROUNDS.map((bg) => (
            <Card
              key={bg.id}
              shadow="sm"
              isPressable
              onPress={() => changeBackground(bg.url)}
              className={`${
                userStore.backgroundUrl === bg.url
                  ? "border-success border-2"
                  : ""
              }`}
            >
              <CardBody className="overflow-visible p-0">
                {isImage(bg.url) && (
                  <Image
                    shadow="sm"
                    radius="lg"
                    width="100%"
                    alt={bg.name}
                    className="w-full object-cover h-[125px]"
                    src={bg.url}
                  />
                )}
                {isVideo(bg.url) && (
                  <video
                    className="w-full object-cover h-[125px]"
                    autoPlay
                    loop
                    muted
                  >
                    <source src={bg.url} type="video/mp4" />
                  </video>
                )}
              </CardBody>
              <CardFooter className="text-small justify-between">
                <b>{bg.name}</b>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
});

export default BackgroundSelector;
