import axios from "axios";

const baseURL =
  process.env.REACT_APP_API_URL_OSINT || "http://127.0.0.1:8000/api";

const osintApiClient = axios.create({
  baseURL: baseURL,
});

export default osintApiClient;
