import React from "react";

interface IndeterminateProgressProps {
  progressBarColor?: string;
  trackColor?: string;
  className?: string;
}

export const IndeterminateProgress: React.FC<IndeterminateProgressProps> = ({
  progressBarColor = "#0072F5",
  trackColor = "#E0E0E0",
  className = "",
}) => {
  return (
    <div
      className={`relative h-1 w-full rounded-full overflow-hidden ${className}`}
      style={{ backgroundColor: trackColor }}
      aria-label="Loading..."
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuetext="Indeterminate"
    >
      <div
        className="absolute top-0 left-0 h-full w-1/2 rounded-full animate-indeterminate-bar"
        style={{
          backgroundColor: progressBarColor,
        }}
      />
    </div>
  );
};
