import React, { useEffect, useCallback, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react-lite";

import appStore, { RunningAppInstance } from "stores/AppStore";
import AppWindow from "./AppWindow";

const DEBOUNCE_DELAY = 200;
const MOBILE_BREAKPOINT = 600;
const DOCK_RESERVED_SPACE = 80;

const WindowManager: React.FC = observer(() => {
  const { runningApps } = appStore;
  const resizeTimer = useRef<any>(null);

  const handleViewportResize = useCallback(() => {
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const isMobile = screenW < MOBILE_BREAKPOINT;

    Array.from(runningApps.values()).forEach((inst: RunningAppInstance) => {
      if (inst.minimized) return;

      if (inst.isMaximized) {
        appStore.updateAppPositionAndSize(
          inst.localInstanceId,
          { x: 0, y: 0 },
          { width: screenW, height: screenH },
          { width: screenW, height: screenH - 40 }
        );
      } else {
        let { x, y } = inst.position;
        let { width: w, height: h } = inst.windowSize;

        if (isMobile) {
          w = screenW;

          if (h > screenH - DOCK_RESERVED_SPACE) {
            h = screenH - DOCK_RESERVED_SPACE;
          }

          x = 0;

          if (y < 0) y = 0;

          if (y + h > screenH) {
            y = Math.max(0, screenH - h - DOCK_RESERVED_SPACE);
          }
        } else {
          if (w > screenW) w = screenW;
          if (h > screenH) h = screenH;

          if (x + w < 0) x = -w + 20;
          if (x > screenW) x = screenW - 20;

          if (y < 0) y = 0;
          if (y + h > screenH) {
            y = Math.max(0, screenH - h);
          }
        }

        const newPos = { x, y };
        const newSize = { width: w, height: h };
        const newIframeSize = { width: w, height: h - 40 };

        appStore.updateAppPositionAndSize(
          inst.localInstanceId,
          newPos,
          newSize,
          newIframeSize
        );
      }
    });
  }, [runningApps]);

  useEffect(() => {
    function onResize() {
      clearTimeout(resizeTimer.current);
      resizeTimer.current = setTimeout(handleViewportResize, DEBOUNCE_DELAY);
    }
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
      clearTimeout(resizeTimer.current);
    };
  }, [handleViewportResize]);

  return (
    <AnimatePresence>
      {Array.from(runningApps.values()).map((appInstance) => {
        if (appInstance.minimized) return null;

        return (
          <AppWindow
            key={appInstance.localInstanceId}
            appInstance={appInstance}
            onClose={() =>
              appStore.closeAppInstance(appInstance.localInstanceId)
            }
            onMinimize={() =>
              appStore.minimizeAppInstance(appInstance.localInstanceId)
            }
            onRestore={() =>
              appStore.restoreAppInstance(appInstance.localInstanceId)
            }
            onBringToFront={() =>
              appStore.bringAppToFront(appInstance.localInstanceId)
            }
            onUpdatePositionAndSize={(pos, size, iframeSize) =>
              appStore.updateAppPositionAndSize(
                appInstance.localInstanceId,
                pos,
                size,
                iframeSize
              )
            }
          />
        );
      })}
    </AnimatePresence>
  );
});

export default WindowManager;
