import React from "react";
import { observer } from "mobx-react-lite";
import { OsynkLocalStore } from "./store/OsynkLocalStore";
import ChatIA from "./components/ui/Chat";

interface OsynkProps {
  instanceId?: string;
  localStore?: OsynkLocalStore;
}

const Osynk: React.FC<OsynkProps> = observer(({ instanceId, localStore }) => {
  if (!localStore) {
    return <div>OsynkLocalStore not found</div>;
  }
  return (
    <div className="bg-theme-n-8">
      <ChatIA localStore={localStore} />
    </div>
  );
});

export default Osynk;
