import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { observer } from "mobx-react-lite";
import { Rnd, RndResizeCallback } from "react-rnd";
import clsx from "clsx";
import { motion } from "framer-motion";

import selectionStore from "stores/SelectionStore";
import appStore, { RunningAppInstance } from "stores/AppStore";
import userStore from "stores/UserStore";

import WindowTopBar from "./WindowTopBar";
import WindowContent from "./WindowContent";
import { WindowLoader } from "./WindowLoader";
import NoVNCViewer from "components/NoVNCViewer/VncViewer";
import { NoVncLocalStore } from "components/NoVNCViewer/store/NoVncLocalStore";

const SNAP_TRIGGER = 250;
const TOP_SNAP_TRIGGER = 60;
const PARTIAL_OUT_MARGIN = 250;
const TOP_OUT_LIMIT = -10;

interface AppWindowProps {
  appInstance: RunningAppInstance;
  onClose: () => void;
  onMinimize: () => void;
  onRestore: () => void;
  onBringToFront: () => void;
  onUpdatePositionAndSize: (
    position: { x: number; y: number },
    windowSize: { width: number; height: number },
    iframeSize: { width: number; height: number }
  ) => void;
}

const windowVariants = {
  initial: { opacity: 0, scale: 0.9 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.9 },

  minimized: ({ deltaX, deltaY }: { deltaX: number; deltaY: number }) => ({
    opacity: 0,
    scale: 0.1,
    x: deltaX,
    y: deltaY,
    transition: { duration: 0.4, ease: "easeInOut" },
  }),
};

const AppWindow: React.FC<AppWindowProps> = observer((props) => {
  const {
    appInstance,
    onClose,
    onMinimize,
    onRestore,
    onBringToFront,
    onUpdatePositionAndSize,
  } = props;

  const {
    localInstanceId,
    isMaximized,
    minimized,
    position,
    windowSize,
    iframeSize,
    zIndex,
    isLoading,
    screenUrl,
    localStore,
  } = appInstance;

  const {
    title,
    image: appLogoUrl,
    backgroundColor,
    progressBarColor,
    component: AppComponent,
    noScrollArea,
    requiresBackend,
  } = appInstance.appDefinition;

  const rndRef = useRef<Rnd>(null);
  const titleBarRef = useRef<HTMLDivElement>(null);

  const [isClosing, setIsClosing] = useState(false);
  const [isMinimizing, setIsMinimizing] = useState(false);
  const [minimizeDelta, setMinimizeDelta] = useState({ deltaX: 0, deltaY: 0 });

  const [lastNormalPos, setLastNormalPos] = useState(position);
  const [lastNormalSize, setLastNormalSize] = useState(windowSize);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 600);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  const [snapPreviewRect, setSnapPreviewRect] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  } | null>(null);

  const getTitleBarHeight = useCallback(() => {
    return titleBarRef.current?.offsetHeight || 40;
  }, []);

  const handleClose = useCallback(() => {
    setIsClosing(true);
  }, []);

  useEffect(() => {
    if (isClosing) {
      const t = setTimeout(() => onClose(), 400);
      return () => clearTimeout(t);
    }
  }, [isClosing, onClose]);

  const handleMinimize = useCallback(() => {
    const dockX = window.innerWidth / 2 - windowSize.width / 2;
    const dockY = window.innerHeight - 60;
    setMinimizeDelta({
      deltaX: dockX - position.x,
      deltaY: dockY - position.y,
    });
    setIsMinimizing(true);
  }, [position.x, position.y, windowSize.width]);

  useEffect(() => {
    if (isMinimizing) {
      const t = setTimeout(() => onMinimize(), 400);
      return () => clearTimeout(t);
    }
  }, [isMinimizing, onMinimize]);

  const getAnimationState = () => {
    if (isClosing) return "exit";
    if (isMinimizing) return "minimized";
    return "animate";
  };
  const handleMaximizeRestore = useCallback(() => {
    onBringToFront();
    if (isMaximized) {
      appStore.setIsMaximized(localInstanceId, false);
      onUpdatePositionAndSize(lastNormalPos, lastNormalSize, {
        width: lastNormalSize.width,
        height: lastNormalSize.height - getTitleBarHeight(),
      });
    } else {
      setLastNormalPos(position);
      setLastNormalSize(windowSize);

      appStore.setIsMaximized(localInstanceId, true);
      const w = window.innerWidth;
      const h = window.innerHeight;

      onUpdatePositionAndSize(
        { x: 0, y: 0 },
        { width: w, height: h },
        { width: w, height: h - getTitleBarHeight() }
      );
    }
  }, [
    isMaximized,
    onBringToFront,
    localInstanceId,
    onUpdatePositionAndSize,
    position,
    windowSize,
    lastNormalPos,
    lastNormalSize,
    getTitleBarHeight,
  ]);

  function getSnapRect(x: number, y: number, w: number, h: number) {
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;

    if (x < -SNAP_TRIGGER) {
      return { x: 0, y: 0, width: screenW / 2, height: screenH };
    }

    if (x + w > screenW + SNAP_TRIGGER) {
      return { x: screenW / 2, y: 0, width: screenW / 2, height: screenH };
    }

    if (y < -TOP_SNAP_TRIGGER) {
      return { x: 0, y: 0, width: screenW, height: screenH };
    }
    return null;
  }

  function applySnapRect(rect: {
    x: number;
    y: number;
    width: number;
    height: number;
  }) {
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;

    if (
      rect.x === 0 &&
      rect.y === 0 &&
      rect.width === screenW &&
      rect.height === screenH
    ) {
      setLastNormalPos(position);
      setLastNormalSize(windowSize);
      appStore.setIsMaximized(localInstanceId, true);
    } else {
      appStore.setIsMaximized(localInstanceId, false);
    }

    onUpdatePositionAndSize(
      { x: rect.x, y: rect.y },
      { width: rect.width, height: rect.height },
      {
        width: rect.width,
        height: rect.height - getTitleBarHeight(),
      }
    );
    setSnapPreviewRect(null);
  }

  const handleTitleBarPointerDown = (
    e: React.PointerEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    if (e.button !== 0) return;
    if (isMaximized || isMobile) return;

    onBringToFront();
    setDragging(true);

    const domNode = rndRef.current?.getSelfElement();
    if (domNode) {
      const rect = domNode.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
  };

  useEffect(() => {
    function handlePointerMove(ev: PointerEvent) {
      if (!dragging) return;
      ev.preventDefault();

      const newX = ev.clientX - dragOffset.x;
      const newY = ev.clientY - dragOffset.y;

      const snapRect = getSnapRect(
        newX,
        newY,
        windowSize.width,
        windowSize.height
      );
      setSnapPreviewRect(snapRect);

      let finalX = newX;
      let finalY = newY;
      const { width: w, height: h } = windowSize;
      const screenW = window.innerWidth;

      if (finalX < -PARTIAL_OUT_MARGIN) {
        finalX = -PARTIAL_OUT_MARGIN;
      }

      if (finalX + w > screenW + PARTIAL_OUT_MARGIN) {
        finalX = screenW + PARTIAL_OUT_MARGIN - w;
      }

      if (finalY < TOP_OUT_LIMIT) {
        finalY = TOP_OUT_LIMIT;
      }

      onUpdatePositionAndSize(
        { x: finalX, y: finalY },
        { ...windowSize },
        { ...iframeSize }
      );
    }

    function handlePointerUp(ev: PointerEvent) {
      if (ev.button !== 0) return;
      if (!dragging) return;
      setDragging(false);

      if (snapPreviewRect) {
        applySnapRect(snapPreviewRect);
      } else {
        let x = position.x;
        let y = position.y;
        const { width: w, height: h } = windowSize;
        const screenW = window.innerWidth;

        if (x < -PARTIAL_OUT_MARGIN) {
          x = -PARTIAL_OUT_MARGIN;
        }

        if (x + w > screenW + PARTIAL_OUT_MARGIN) {
          x = screenW + PARTIAL_OUT_MARGIN - w;
        }

        if (y < TOP_OUT_LIMIT) {
          y = TOP_OUT_LIMIT;
        }

        setLastNormalPos({ x, y });
        setLastNormalSize({ width: w, height: h });

        onUpdatePositionAndSize(
          { x, y },
          { width: w, height: h },
          { width: w, height: h - getTitleBarHeight() }
        );
        setSnapPreviewRect(null);
      }
    }

    window.addEventListener("pointermove", handlePointerMove);
    window.addEventListener("pointerup", handlePointerUp);
    return () => {
      window.removeEventListener("pointermove", handlePointerMove);
      window.removeEventListener("pointerup", handlePointerUp);
    };
  }, [
    dragging,
    dragOffset,
    position,
    windowSize,
    iframeSize,
    snapPreviewRect,
    onUpdatePositionAndSize,
    getTitleBarHeight,
  ]);

  const handleResizeStop: RndResizeCallback = useCallback(
    (e, direction, elementRef, delta, pos) => {
      if (isMobile) return;

      const w = (elementRef as HTMLDivElement).offsetWidth;
      const h = (elementRef as HTMLDivElement).offsetHeight;

      let x = pos.x;
      let y = pos.y;
      const screenW = window.innerWidth;

      if (x < -PARTIAL_OUT_MARGIN) {
        x = -PARTIAL_OUT_MARGIN;
      }

      if (x + w > screenW + PARTIAL_OUT_MARGIN) {
        x = screenW + PARTIAL_OUT_MARGIN - w;
      }

      if (y < TOP_OUT_LIMIT) {
        y = TOP_OUT_LIMIT;
      }

      appStore.setIsMaximized(localInstanceId, false);

      setLastNormalPos({ x, y });
      setLastNormalSize({ width: w, height: h });

      onUpdatePositionAndSize(
        { x, y },
        { width: w, height: h },
        { width: w, height: h - getTitleBarHeight() }
      );
    },
    [isMobile, localInstanceId, onUpdatePositionAndSize, getTitleBarHeight]
  );

  const renderContent = useMemo(() => {
    if (isLoading && !screenUrl && !AppComponent) {
      return (
        <WindowLoader
          title={title}
          logoUrl={appLogoUrl}
          backgroundColor={backgroundColor}
          progressBarColor={progressBarColor}
        />
      );
    }

    if (AppComponent) {
      if (noScrollArea) {
        if (isLoading) {
          return (
            <div className="flex-grow w-full h-full relative">
              <WindowLoader
                title={title}
                logoUrl={appLogoUrl}
                backgroundColor={backgroundColor}
                progressBarColor={progressBarColor}
              />
            </div>
          );
        }
        return (
          <div className="flex-grow overflow-hidden w-full h-full p-4">
            <AppComponent
              instanceId={localInstanceId}
              localStore={localStore}
            />
          </div>
        );
      }
      return (
        <WindowContent windowType="component" isLoading={isLoading}>
          <AppComponent instanceId={localInstanceId} localStore={localStore} />
        </WindowContent>
      );
    }

    if (screenUrl && localStore instanceof NoVncLocalStore) {
      return (
        <WindowContent windowType="iframe">
          <div className="relative w-full h-full">
            <NoVNCViewer
              url={screenUrl}
              localId={localInstanceId}
              localVncStore={localStore}
              onBringToFront={onBringToFront}
              style={{
                opacity: isLoading ? 0 : 1,
                pointerEvents: isLoading ? "none" : "auto",
                transition: "opacity 0.2s ease-in-out",
              }}
            />
            {isLoading && (
              <div className="absolute inset-0 z-[999] flex">
                <WindowLoader
                  title={title}
                  logoUrl={appLogoUrl}
                  backgroundColor={backgroundColor}
                  progressBarColor={progressBarColor}
                />
              </div>
            )}
          </div>
        </WindowContent>
      );
    }

    return (
      <div className="flex-grow w-full h-full">
        <WindowLoader
          title={title}
          logoUrl={appLogoUrl}
          backgroundColor={backgroundColor}
          progressBarColor={progressBarColor}
        />
      </div>
    );
  }, [
    AppComponent,
    noScrollArea,
    isLoading,
    screenUrl,
    title,
    appLogoUrl,
    backgroundColor,
    progressBarColor,
    localInstanceId,
    localStore,
    onBringToFront,
  ]);

  return (
    <>
      {snapPreviewRect && (
        <div
          style={{
            position: "fixed",
            left: snapPreviewRect.x,
            top: snapPreviewRect.y,
            width: snapPreviewRect.width,
            height: snapPreviewRect.height,
            backgroundColor: "rgba(0, 150, 255, 0.2)",
            pointerEvents: "none",
            zIndex: 999999,
          }}
        />
      )}

      <Rnd
        ref={rndRef}
        size={{ width: windowSize.width, height: windowSize.height }}
        position={{ x: position.x, y: position.y }}
        style={{ zIndex }}
        disableDragging
        enableResizing={
          !isMaximized &&
          !isMobile && {
            top: true,
            right: true,
            bottom: true,
            left: true,
            topRight: true,
            bottomRight: true,
            bottomLeft: true,
            topLeft: true,
          }
        }
        minWidth={320}
        minHeight={240}
        onResizeStop={handleResizeStop}
        onMouseDown={onBringToFront}
      >
        <motion.div
          variants={windowVariants}
          initial="initial"
          animate={getAnimationState()}
          exit="exit"
          custom={{
            deltaX: minimizeDelta.deltaX,
            deltaY: minimizeDelta.deltaY,
          }}
          transition={{ duration: 0.4 }}
          className={clsx(
            "relative flex flex-col w-full h-full",
            "window-component",
            "rounded-2xl overflow-hidden select-none",
            "bg-[radial-gradient(circle_at_26%_0,rgba(178,198,255,0.15),rgba(243,243,244,0.03)_33%,rgba(255,255,255,0)_56%,rgba(57,59,76,0.07))]",
            "shadow-[0_0_1px_0_rgba(0,0,0,0.25),_0_4px_8px_0_rgba(0,0,0,0.05),_0_16px_32px_0_rgba(0,0,0,0.05)]",
            "before:absolute before:inset-0 before:-z-10 before:bg-[#0a1319CC] before:rounded-inherit before:backdrop-filter before:backdrop-blur-[40px] before:backdrop-saturate-200 border border-lightColor/10",
            selectionStore.isSelecting && "pointer-events-none"
          )}
        >
          <div
            ref={titleBarRef}
            className="window-title-bar select-none z-[10]"
            onPointerDown={handleTitleBarPointerDown}
            onDoubleClick={handleMaximizeRestore}
          >
            <WindowTopBar
              title={title || "Untitled"}
              isMaximized={isMaximized}
              onClose={handleClose}
              onMinimize={handleMinimize}
              onMaximize={handleMaximizeRestore}
              onRestoreSize={onRestore}
              appLogoUrl={appLogoUrl}
              vpnStatus={userStore.vpnStatus}
              requiresBackend={requiresBackend}
            />
          </div>

          {renderContent}
        </motion.div>
      </Rnd>
    </>
  );
});

export default AppWindow;
