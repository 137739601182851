import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { observer } from "mobx-react-lite";
import appStore from "stores/AppStore";
import userStore from "stores/UserStore";
import { useTranslation } from "react-i18next";
import themes from "./themes.json";
import { HeroHighlight, Highlight } from "components/UI/hero-highlight";
import { motion } from "framer-motion";

interface HistoryEntry {
  id: number;
  command: string | null;
  output: React.ReactNode;
}

interface Theme {
  name: string;
  foreground: string;
  background: string;
  cursorColor: string;
  black: string;
  red: string;
  green: string;
  yellow: string;
  blue: string;
  purple: string;
  cyan: string;
  white: string;
  brightBlack: string;
  brightRed: string;
  brightGreen: string;
  brightYellow: string;
  brightBlue: string;
  brightPurple: string;
  brightCyan: string;
  brightWhite: string;
}

const Terminal: React.FC = observer(() => {
  const defaultThemeName = "Slate";
  const [inputValue, setInputValue] = useState("");
  const [history, setHistory] = useState<HistoryEntry[]>([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [isBusy, setIsBusy] = useState(false);

  const [currentTheme, setCurrentTheme] = useState<Theme>(() => {
    const theme = themes.find((t) => t.name === defaultThemeName);
    return theme || themes[0];
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const terminalRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [hostname, setHostname] = useState("SynkOS");

  const availableCommands = [
    "help",
    "clear",
    "exit",
    "ls",
    "launch",
    "theme",
    "echo",
    "whoami",
    "date",
    "banner",
    "./synk-connect.sh",
  ];

  const getRandomDelay = (min: number = 500, max: number = 1000): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const animateMessage = async (
    baseMessage: string,
    delay: number = 500,
    iterations: number = 3
  ) => {
    let output = baseMessage;
    addHistoryEntry({ command: null, output: output + "..." });

    for (let i = 0; i < iterations; i++) {
      await new Promise((resolve) => setTimeout(resolve, delay));
      output += ".";
      setHistory((prev) => {
        const lastEntry = prev[prev.length - 1];
        return [...prev.slice(0, -1), { ...lastEntry, output: output + "]" }];
      });
    }
  };

  const runSynkConnect = async (): Promise<void> => {
    setIsBusy(true);

    addHistoryEntry({ command: "./synk-connect.sh", output: "" });

    await animateMessage("[Connecting to Synk Network", 900);

    await animateMessage("[Authentication successful", 200);

    await animateMessage("[Loading secure Synk environment", 700);

    await new Promise((resolve) => setTimeout(resolve, 600));

    addHistoryEntry({ command: null, output: getBanner() });

    setIsBusy(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [history]);

  useLayoutEffect(() => {
    if (endRef.current) {
      endRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [history]);

  const handleInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.ctrlKey && e.key === "l") {
      e.preventDefault();
      clearHistory();
    } else if (e.key === "Enter") {
      e.preventDefault();
      const command = inputValue;
      executeCommand(command);
      setInputValue("");
      setHistoryIndex(-1);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      let nonEmptyCommands = history
        .filter((entry) => entry.command && entry.command.trim() !== "")
        .reverse();
      if (nonEmptyCommands.length > 0) {
        const newIndex = historyIndex + 1;
        if (newIndex < nonEmptyCommands.length) {
          setHistoryIndex(newIndex);
          setInputValue(nonEmptyCommands[newIndex].command!);
        }
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      let nonEmptyCommands = history
        .filter((entry) => entry.command && entry.command.trim() !== "")
        .reverse();
      if (nonEmptyCommands.length > 0) {
        const newIndex = historyIndex - 1;
        if (newIndex >= 0) {
          setHistoryIndex(newIndex);
          setInputValue(nonEmptyCommands[newIndex].command!);
        } else {
          setHistoryIndex(-1);
          setInputValue("");
        }
      }
    } else if (e.key === "Tab") {
      e.preventDefault();
      handleTabCompletion();
    }
  };

  const executeCommand = (command: string) => {
    const output = handleCommand(command);

    if (output instanceof Promise) {
      setIsBusy(true);
      output.then((resolvedOutput) => {
        if (resolvedOutput) {
          addHistoryEntry({ command, output: resolvedOutput });
        }
        setIsBusy(false);
      });
    } else if (output) {
      addHistoryEntry({ command, output });
    } else {
      addHistoryEntry({ command, output: "" });
    }
    setInputValue("");
    setHistoryIndex(-1);
  };

  const addHistoryEntry = (entry: {
    command: string | null;
    output: React.ReactNode;
  }) => {
    setHistory((prev) => [
      ...prev,
      {
        id: prev.length,
        command: entry.command,
        output: entry.output,
      },
    ]);
  };

  const clearHistory = () => {
    setHistory([]);
    setHistoryIndex(-1);
  };

  const handleCommand = (
    command: string
  ): React.ReactNode | Promise<React.ReactNode | void> => {
    const args = command.split(" ").filter(Boolean);
    const mainCommand = args[0] || "";
    const restArgs = args.slice(1);
    let output: React.ReactNode = "";

    switch (mainCommand) {
      case "":
        break;
      case "help":
        output = `Available commands: ${availableCommands.join(
          ", "
        )}\nType 'help' to see this list.`;
        break;
      case "clear":
        clearHistory();
        break;
      case "exit":
        const terminalInstance = Array.from(appStore.runningApps.values()).find(
          (inst) => inst.appDefinition.appName.toLowerCase() === "terminal"
        );
        if (terminalInstance) {
          appStore.closeAppInstance(terminalInstance.localInstanceId);
        }
        break;
      case "ls":
        output = appStore.availableApps.map((app) => app.appName).join("  ");
        break;
      case "launch":
        if (restArgs.length > 0) {
          const appName = restArgs.join(" ");
          appStore.focusOrLaunchApp(appName);
          output = `Launching ${appName}...`;
        } else {
          output = `Please specify an application to launch.`;
        }
        break;
      case "theme":
        if (restArgs.length > 0) {
          const themeArg = restArgs[0];
          output = handleThemeCommand(themeArg, restArgs.slice(1));
        } else {
          output = `Usage: theme [ls | set <theme-name> | random]\nAvailable themes: ${themes
            .map((t) => t.name)
            .join(", ")}`;
        }
        break;
      case "echo":
        output = restArgs.join(" ");
        break;
      case "whoami":
        output = userStore.name || "guest";
        break;
      case "date":
        output = new Date().toString();
        break;
      case "banner":
        output = getBanner();
        break;
      case "./synk-connect.sh":
        return runSynkConnect();
      default:
        output = `Command not found: ${mainCommand}. Type 'help' to get started.`;
    }

    return output;
  };

  const handleLaunchFirefox = async (): Promise<void> => {
    setIsBusy(true);

    await animateMessage("[Launching Firefox in a secure, no-logs container");

    appStore.focusOrLaunchApp("firefox");

    await new Promise((resolve) => setTimeout(resolve, 500));
    addHistoryEntry({
      command: null,
      output: (
        <HeroHighlight>
          <motion.h1
            initial={{
              opacity: 0,
              y: 20,
            }}
            animate={{
              opacity: 1,
              y: [20, -5, 0],
            }}
            transition={{
              duration: 0.5,
              ease: [0.4, 0.0, 0.2, 1],
            }}
            className="mt-2"
          >
            <Highlight className="text-white font-bold text-[15px] p-2">
              Launching soon for everyone!
            </Highlight>
            <br /> <br />
            Stay tuned for the Synk experience!
          </motion.h1>
        </HeroHighlight>
      ),
    });

    setIsBusy(false);
  };

  const handleThemeCommand = (
    arg: string,
    restArgs: string[]
  ): React.ReactNode => {
    let output = "";
    if (arg === "ls") {
      const themeList = themes.map((t) => t.name.toLowerCase()).join(", ");
      output = `Available themes: ${themeList}`;
    } else if (arg === "random") {
      const randomTheme = themes[Math.floor(Math.random() * themes.length)];
      setCurrentTheme(randomTheme);
      output = `Theme '${randomTheme.name}' applied successfully!`;
    } else if (arg === "set") {
      const themeName = restArgs.join(" ").toLowerCase();
      const theme = themes.find((t) => t.name.toLowerCase() === themeName);
      if (theme) {
        setCurrentTheme(theme);
        output = `Theme '${theme.name}' applied successfully!`;
      } else {
        output = `Theme '${themeName}' not found. Type 'theme ls' to see available themes.`;
      }
    } else {
      output = `Invalid 'theme' command. Usage: theme [ls | set <theme-name> | random]`;
    }
    return output;
  };

  const handleTabCompletion = () => {
    const possibleCommands = availableCommands.filter((cmd) =>
      cmd.startsWith(inputValue)
    );
    if (possibleCommands.length === 1) {
      setInputValue(possibleCommands[0] + " ");
    }
  };

  const getLogo = (): React.ReactNode => {
    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 98 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      ></svg>
    );
  };

  const getBanner = (): React.ReactNode => {
    return (
      <pre>
        {`
███████╗██╗   ██╗███╗   ██╗██╗  ██╗
██╔════╝╚██╗ ██╔╝████╗  ██║██║ ██╔╝
███████╗ ╚████╔╝ ██╔██╗ ██║█████╔╝ 
╚════██║  ╚██╔╝  ██║╚██╗██║██╔═██╗ 
███████║   ██║   ██║ ╚████║██║  ██╗
╚══════╝   ╚═╝   ╚═╝  ╚═══╝╚═╝  ╚═╝

Welcome to the SynkOS!
`}
      </pre>
    );
  };

  const Ps1 = () => {
    return (
      <div>
        <span style={{ color: currentTheme.green }}>guest</span>
        <span style={{ color: currentTheme.white }}>@</span>
        <span style={{ color: currentTheme.blue }}>{hostname}</span>
        <span style={{ color: currentTheme.white }}>:~$</span>
      </div>
    );
  };

  return (
    <>
      <div
        className="w-full h-full flex justify-center @container"
        style={{
          color: currentTheme.foreground,
        }}
      >
        <div
          ref={terminalRef}
          className="w-full p-2 overflow-y-auto font-mono text-sm"
          onClick={() => {
            inputRef.current?.focus();
          }}
        >
          {history.map((entry) => (
            <div key={entry.id}>
              {entry.command && (
                <div className="flex flex-row space-x-2">
                  <div className="flex-shrink">
                    <Ps1 />
                  </div>
                  <div className="flex-grow">{entry.command}</div>
                </div>
              )}
              {entry.output && (
                <div className="mb-2" style={{ lineHeight: "normal" }}>
                  {entry.output}
                </div>
              )}
            </div>
          ))}
          {!isBusy && (
            <div className="flex flex-row space-x-2">
              <div className="flex-shrink">
                <Ps1 />
              </div>
              <input
                ref={inputRef}
                type="text"
                className="flex-grow bg-transparent outline-none"
                style={{ color: currentTheme.foreground }}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleInput}
                spellCheck={false}
                autoFocus
              />
            </div>
          )}
          <div ref={endRef} />
        </div>
      </div>
    </>
  );
});

export default Terminal;
