import React from "react";
import { Button } from "@nextui-org/react";
import { Minus, Maximize2, X, CornerUpLeft } from "lucide-react";

interface WindowEditButtonsProps {
  onMinimize?: () => void;
  onMaximize: () => void;
  onClose: () => void;
  isMaximized: boolean;
  isMinimizable?: boolean;
  onRestoreSize?: () => void;
}

const WindowEditButtons: React.FC<WindowEditButtonsProps> = ({
  onMinimize,
  onMaximize,
  onClose,
  isMaximized,
  isMinimizable = true,
  onRestoreSize,
}) => {
  return (
    <div className="flex gap-2">
      {isMinimizable && onMinimize && (
        <Button
          size="sm"
          isIconOnly
          aria-label="Minimiser"
          variant="light"
          onClick={onMinimize}
          className="w-6 h-6"
        >
          <Minus size={14} />
        </Button>
      )}

      <Button
        size="sm"
        isIconOnly
        aria-label={isMaximized ? "Restaurer" : "Maximiser"}
        variant="light"
        onClick={onMaximize}
        className="w-6 h-6"
      >
        {isMaximized ? <CornerUpLeft size={14} /> : <Maximize2 size={14} />}
      </Button>

      <Button
        size="sm"
        isIconOnly
        aria-label="Fermer"
        variant="light"
        onClick={onClose}
        className="w-6 h-6 data-[hover=true]:bg-red-500/20"
      >
        <X size={14} />
      </Button>
    </div>
  );
};

export default WindowEditButtons;
