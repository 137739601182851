import React from "react";
import { observer } from "mobx-react-lite";
import { useAuth0 } from "@auth0/auth0-react";

import userStore from "stores/UserStore";

import { Login } from "components/Login/Login";
import Preboot from "components/Preboot/Preboot";

import OS from "./components/OS/OS";

const SynkOSv2: React.FC = observer(() => {
  const auth0 = useAuth0();

  const { showPreboot, isAuthenticated } = userStore;

  React.useEffect(() => {
    userStore.initializeAuth(auth0);
  }, [auth0.isAuthenticated]);

  if (!isAuthenticated) {
    return <Login />;
  }
  if (showPreboot) {
    return <Preboot />;
  }

  return <OS />;
});

export default SynkOSv2;
