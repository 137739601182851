export interface Background {
  id: number;
  name: string;
  url: string;
}

export const BACKGROUNDS: Background[] = [
  { id: 1, name: "Pyramids", url: "/images/customBG/SYNK3.png" },
  { id: 2, name: "Mountains", url: "/images/customBG/SYNK1.png" },
  { id: 3, name: "New York", url: "/images/customBG/SYNK4.png" },
  { id: 4, name: "Paris", url: "/images/customBG/SYNK2.png" },
  { id: 5, name: "Earth", url: "/images/customBG/SYNK5.png" },
  { id: 6, name: "Sydney", url: "/images/customBG/SYNK6.png" },
];

export const DEFAULT_BACKGROUND = BACKGROUNDS[0].url;

export const isImage = (url: string) => /\.(png|jpe?g|gif)$/i.test(url);
export const isVideo = (url: string) => /\.(mp4|mov|webm)$/i.test(url);
