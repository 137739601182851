import React from "react";

type WatchIconProps = {
  color?: string;
  width?: number;
  height?: number;
};

export default function WatchIcon({
  color = "#3680FF",
  width = 45,
  height = 56,
}: WatchIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 4C14 1.79086 15.7909 0 18 0H28C30.2091 0 32 1.79086 32 4V8C32 10.2091 30.2091 12 28 12H18C15.7909 12 14 10.2091 14 8V4Z"
        fill={color}
      />
      <path
        d="M14 33C14 30.7909 15.7909 29 18 29H28C30.2091 29 32 30.7909 32 33V52C32 54.2091 30.2091 56 28 56H18C15.7909 56 14 54.2091 14 52V33Z"
        fill={color}
      />

      <g filter="url(#filter0_biii_243_10)">
        <rect y="8" width="45" height="40" rx="12" fill="#182C3B" />

        <rect
          y="8"
          width="45"
          height="40"
          rx="12"
          fill={color}
          fillOpacity="0.3"
        />
      </g>

      <path
        d="M21 21C19.8954 21 19 21.8954 19 23V31C19 32.1046 19.8954 33 21 33H26C27.1046 33 28 32.1046 28 31C28 29.8954 27.1046 29 26 29H23V23C23 21.8954 22.1046 21 21 21Z"
        fill="white"
        fillOpacity="0.7"
      />

      <defs>
        <filter
          id="filter0_biii_243_10"
          x="-14"
          y="-6"
          width="73"
          height="68"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_243_10"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_243_10"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_243_10"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_innerShadow_243_10"
            result="effect3_innerShadow_243_10"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_innerShadow_243_10"
            result="effect4_innerShadow_243_10"
          />
        </filter>
      </defs>
    </svg>
  );
}
