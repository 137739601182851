import { Routes, Route } from "react-router-dom";
import { useContext } from "react";
import { ReleaseContext } from "components/ReleaseContext/ReleaseContext";
import { ROUTES } from "routes/routes";
import { HomePage } from "pages/HomePage/HomePage";
import { CountDownPage } from "pages/CountDownPage/CountDownPage";
import IsolatedApp from "pages/IsolatedApp/IsolatedApp";
import InstanceStarter from "pages/InstanceStarter/InstanceStarter";
import NotFoundPage from "pages/NotFoundPage/NotFoundPage";
import ProtectedRoute from "pages/ProtectedRoute/ProtectedRoute";
import RateLimit from "pages/RateLimit/RateLimit";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import SynkOSv2 from "pages/SynkOSv2/SynkOSv2";
import { DNSOverridePage } from "pages/DNSOverridePage/DNSOverridePage";
import SynkSearchEngine from "pages/SynkSearchEngine/SynkSearchEngine";

import LoadingPage from "pages/IsolatedApp/components/LoadingPage";
import Test2 from "pages/Test2/Test2";
import { Test3 } from "pages/Test3/Test3";
import { Test4 } from "pages/Test4/Test4";
import { SynkSessionStarter } from "pages/Test5/Test5";
import { Test6 } from "pages/Test6/Test6";
import { Test8 } from "pages/Test8/Test8";
import SynkINT from "pages/SynkINT/SynkINT";
import { PrivacyPolicyExtension } from "pages/PrivacyPolicy/Extension/PrivacyPolicyExtension";
import ChatIA from "pages/SynkINT/components/ChatIA/ChatIA";
import { Test9 } from "pages/Test9/Test9";
import { Test7 } from "pages/Test7/Test7";
import OSynk from "pages/OSynk/OSynk";
import Test10 from "pages/Test10/Test10";
import HitLimit from "pages/SynkINT/components/HitLimit/HitLimit";
import SynkStore from "locaLapps/SynkStore/SynkStore";
import Test11 from "pages/Test11/Test11";
import Test12 from "pages/Test12/Test12";

const AppRoutes = () => {
  const { isReleased } = useContext(ReleaseContext);

  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<HomePage />} />

      <Route path={ROUTES.ISOLATEDAPP} element={<IsolatedApp />} />
      <Route path={ROUTES.START_FX} element={<InstanceStarter />} />
      <Route path={ROUTES.RATE_LIMIT} element={<RateLimit />} />
      <Route path={ROUTES.ERROR_PAGE} element={<ErrorPage />} />
      <Route path={ROUTES.OS} element={<SynkOSv2 />} />
      <Route path={ROUTES.DNS_OVERRIDE} element={<DNSOverridePage />} />
      <Route path={ROUTES.SEARCHENGINE} element={<SynkSearchEngine />} />
      {/* <Route path={ROUTES.OSYNK} element={<OSynk />} /> */}
      <Route path={ROUTES.SYNKINT} element={<HitLimit />} />
      <Route
        path={ROUTES.PRIVACY_EXTENSION}
        element={<PrivacyPolicyExtension />}
      />

      <Route path={ROUTES.OSYNK} element={<HitLimit />} />
      {/* <Route path={ROUTES.TEST8} element={<Test8 />} /> */}
      {/* <Route path={ROUTES.TEST7} element={<Test7 />} /> */}

      {/* <Route path={ROUTES.TEST9} element={<Test9 />} /> */}
      {/* <Route path={ROUTES.TEST} element={<HomePage />} /> */}
      {/* <Route path={ROUTES.TEST2} element={<Test2 />} /> */}
      {/* <Route path={ROUTES.TEST3} element={<Test3 />} /> */}
      {/* <Route path={ROUTES.TEST4} element={<Test4 />} /> */}
      {/* <Route path={ROUTES.TEST5} element={<SynkSessionStarter />} /> */}
      {/* <Route path={ROUTES.TEST6} element={<Test6 />} /> */}

      {/* <Route path={ROUTES.TEST10} element={<Test10 />} />
      <Route path={ROUTES.TEST11} element={<Test11 />} />
      <Route path={ROUTES.TEST12} element={<Test12 />} /> */}

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
