import React from "react";

type QuestionProps = {
  content: string;
};

const Question = ({ content }: QuestionProps) => {
  return (
    <>
      <div
        data-orientation="horizontal"
        role="none"
        className="shrink-0 bg-gradient-to-b from-synkColor/15 to-slate-600 h-[1px] !mt-20 !-mb-6 flex w-full"
      ></div>

      <div className="max-w-[1150px] lg:items-start transition-width duration-500 lg:flex-row justify-center w-full flex-col items-start -pl-1 flex gap-3 mb-10">
        <div className="flex flex-col justify-start max-w-[750px] w-full">
          <div className="relative border border-gray-200 overflow-hidden bg-gray-50 dark:bg-background dark:border-gray-800 rounded-2xl z-40 w-full p-3">
            <svg
              className="animate-spotlight pointer-events-none absolute opacity-100 -top-10 -left-20 rotate-6 z-[-1]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 3787 2842"
              fill="none"
            >
              <g filter="url(#filter)">
                <ellipse
                  cx="1924.71"
                  cy="273.501"
                  rx="1924.71"
                  ry="273.501"
                  transform="matrix(-0.822377 -0.568943 -0.568943 0.822377 3631.88 2291.09)"
                  fill="white"
                  fillOpacity="0.21"
                ></ellipse>
              </g>
              <defs>
                <filter
                  id="filter"
                  x="0.860352"
                  y="0.838989"
                  width="3785.16"
                  height="2840.26"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="151"
                    result="effect1_foregroundBlur_1065_8"
                  ></feGaussianBlur>
                </filter>
              </defs>
            </svg>
            <h1 className="text-lg relative -tracking-tight gap-1 lg:text-sm">
              {content}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Question;
