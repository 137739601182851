import React from "react";
import { Image } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import userStore, { VPNStatusType } from "stores/UserStore";
import { HoverEffect } from "components/UI/card-hover-effect";
import { observer } from "mobx-react-lite";

const VPNTab = observer(() => {
  const { t } = useTranslation();
  const { vpnStatus, availableVPNStatuses, setVPNStatus } = userStore;

  const handleVPNChange = (key: string) => {
    if (key === "mullvad") {
      setVPNStatus("mullvad");
    }
  };

  const items = availableVPNStatuses.map((status: VPNStatusType) => {
    const isMullvad = status.key === "mullvad";

    const isDisabled = status.key !== vpnStatus && !isMullvad;
    return {
      key: status.key,
      title: t(status.translateKey),
      icon: status.src ? (
        <Image
          src={status.src}
          alt={t(status.translateKey)}
          className={
            isMullvad
              ? "w-12 h-12"
              : "w-12 h-12 grayscale opacity-50 cursor-not-allowed"
          }
        />
      ) : null,

      onClick: () => {
        if (!isDisabled) {
          handleVPNChange(status.key);
        }
      },
      disabled: isDisabled,
    };
  });

  return (
    <div>
      <HoverEffect
        items={items}
        selectedKey={vpnStatus}
        className="w-full mt-1"
      />
    </div>
  );
});

export default VPNTab;
