import { gsap } from "gsap";

export const cursor = () => {
  const cursorEl = document.querySelector(".cursor") as HTMLDivElement | null;
  if (!cursorEl) {
    console.warn("No .cursor element found");
    return;
  }

  const moveCursor = (e: MouseEvent) => {
    gsap.to(cursorEl, {
      duration: 0.3,
      x: e.clientX,
      y: e.clientY,
      ease: "Power1.out",
    });
  };

  document.addEventListener("mousemove", moveCursor);
  document.addEventListener("mouseenter", () =>
    gsap.to(cursorEl, { duration: 0.3, scale: 1, ease: "Power1.out" })
  );
  document.addEventListener("mouseleave", () =>
    gsap.to(cursorEl, { duration: 0.3, scale: 0, ease: "Power1.out" })
  );

  const applyCursorEffect = (
    elements: Element[],
    scale: number,
    filter = "blur(0px)"
  ) => {
    elements.forEach((el) => {
      el.addEventListener("mouseenter", () =>
        gsap.to(cursorEl, {
          duration: 0.3,
          scale,
          ease: "Power1.out",
          filter: "blur(2px)",
        })
      );
      el.addEventListener("mouseleave", () =>
        gsap.to(cursorEl, {
          duration: 0.3,
          scale: 1,
          ease: "Power1.out",
          filter,
        })
      );
    });
  };

  const links = document.querySelectorAll(".focus");
  const headings = document.querySelectorAll(".focus2");

  applyCursorEffect(Array.from(links), 5);
  applyCursorEffect(Array.from(headings), 15);
};
