import React, { useState, useEffect } from "react";
import { gsap } from "gsap";

interface IntroSectionProps {
  onIntroDone?: () => void;
}

const IntroSection: React.FC<IntroSectionProps> = ({ onIntroDone }) => {
  const [introDone, setIntroDone] = useState(false);

  useEffect(() => {
    const tl = gsap.timeline({
      onComplete: () => {
        setIntroDone(true);

        onIntroDone?.();
      },
    });

    tl.from(".intro_wrapper h1", {
      y: "200%",
      duration: 1,
      skewY: 10,
    })
      .to(".inFill", {
        width: "100%",
        duration: 2,
        delay: 0.2,
      })
      .to(".intro_wrapper h1", {
        y: "-200%",
        duration: 1,
        skewY: -10,
      })
      .to(".intro", {
        y: "-100%",
        duration: 1.2,
        delay: -0.7,
        ease: "power3.inOut",
      })
      .to(".intro", {
        autoAlpha: 0,
        delay: 1,
      });
  }, [onIntroDone]);

  if (introDone) return null;

  return (
    <section className="intro z-[99999]">
      <div className="intro_wrapper">
        <h1 className="inFix intro_h1 text-5xl md:text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-b from-synkColor/10 to-synkColor/50">
          SYNK
        </h1>
        <h1 className="inFill intro_h1 text-5xl md:text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-b from-synkColor/10 to-synkColor/50">
          SYNK
        </h1>
      </div>
    </section>
  );
};

export default IntroSection;
