type IconsType = {
  [key: string]: string;
};

const icons: IconsType = {
  "arrow-bottom":
    "M13 5a1 1 0 1 0-2 0v11.586l-5.293-5.293a1 1 0 0 0-1.414 1.414l7 7a1 1 0 0 0 1.414 0l7-7a1 1 0 0 0-1.414-1.414L13 16.586V5z",
  "arrow-left":
    "M10.707 6.707a1 1 0 0 0-1.414-1.414l-6 6a1 1 0 0 0 0 1.414l6 6a1 1 0 0 0 1.414-1.414L6.414 13H20a1 1 0 1 0 0-2H6.414l4.293-4.293z",
  "arrow-down":
    "M4.939 7.939a1.5 1.5 0 0 1 2.121 0L12 12.878l4.939-4.938a1.5 1.5 0 0 1 1.98-.125l.141.125a1.5 1.5 0 0 1 0 2.121l-6 6a1.5 1.5 0 0 1-2.121 0l-6-6a1.5 1.5 0 0 1 0-2.121z",
  "arrow-next":
    "M8.293 5.293a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414-1.414L13.586 12 8.293 6.707a1 1 0 0 1 0-1.414z",
  "arrow-next-fat":
    "M7.939 4.939a1.5 1.5 0 0 1 2.121 0l6 6a1.5 1.5 0 0 1 0 2.121l-6 6a1.5 1.5 0 1 1-2.121-2.121L12.878 12 7.939 7.061a1.5 1.5 0 0 1-.125-1.98l.125-.141z",
  "arrow-right":
    "M13.293 17.293a1 1 0 0 0 1.414 1.414l6-6a1 1 0 0 0 0-1.414l-6-6a1 1 0 0 0-1.414 1.414L17.586 11H4a1 1 0 1 0 0 2h13.586l-4.293 4.293z",
  "arrow-right-fat":
    "M11.293 7.293a1 1 0 0 1 1.414 0l4 4c.026.026.05.053.073.082l.006.008.016.022.042.059.009.015.007.01.014.027.024.044.007.017.01.019.012.033.015.034.007.025.008.02.005.026.012.037.004.028.006.025.003.027.006.032.002.03.003.028v.026L17 12l-.002.033v.026l-.003.026-.002.032-.006.031-.003.028-.006.024-.004.029-.012.036-.005.027-.008.019-.007.026-.015.033-.012.034-.01.018-.007.018-.024.043-.014.028-.007.009-.009.016-.042.058-.012.019-.004.003-.006.009-.073.082-4 4a1 1 0 0 1-1.414-1.414L13.584 13H8a1 1 0 0 1-.993-.883L7 12a1 1 0 0 1 1-1h5.585l-2.292-2.293a1 1 0 0 1-.083-1.32l.083-.094z",
  "arrow-select":
    "M15.56 6.3c.671 0 1.28.163 1.827.49s.989.765 1.316 1.316.49 1.156.49 1.827c0 .501-.094.971-.282 1.412a3.66 3.66 0 0 1-1.944 1.944c-.437.188-.905.282-1.406.282l-7.788-.001 3.031 3.026-1.092 1.092L4.8 12.777l4.911-4.911 1.092 1.092-3.03 3.025 7.787.001c.376 0 .719-.092 1.028-.277a2.11 2.11 0 0 0 .74-.74c.185-.312.277-.657.277-1.033s-.092-.717-.277-1.023a2.11 2.11 0 0 0-.74-.74c-.309-.188-.652-.282-1.028-.282h-.65V6.3h.65z",
  "arrow-swap":
    "M17 2.5A1.5 1.5 0 0 1 18.5 4v12.376l1.439-1.437a1.5 1.5 0 0 1 1.953-.145l.168.145a1.5 1.5 0 0 1 0 2.121l-4 4a1.51 1.51 0 0 1-.144.127l-.015.009-.064.042-.081.057-.021.009-.027.018-.061.026-.064.034-.046.016-.023.011-.039.011-.077.026-.057.012-.03.009-.04.005-.068.014-.058.004-.041.006h-.047L17 21.5l-.058-.004h-.047l-.045-.006-.054-.004-.07-.014-.038-.005-.032-.009-.055-.012-.079-.026-.037-.011-.025-.011-.044-.016-.066-.034-.059-.026-.029-.018-.019-.009-.083-.057-.052-.032c-.075-.058-.123-.1-.168-.145l-4-4a1.5 1.5 0 1 1 2.121-2.121l1.439 1.439V4a1.5 1.5 0 0 1 1.296-1.486L17 2.5zm-9.854.01l.058.004.068.014.04.005.03.009.057.012.077.026.039.011.023.011.046.016.064.034.061.026.027.018.021.009.081.057.054.032.168.145 4 4a1.5 1.5 0 1 1-2.121 2.121L8.5 7.622V20a1.5 1.5 0 0 1-1.296 1.486L7 21.5A1.5 1.5 0 0 1 5.5 20V7.62L4.061 9.061a1.5 1.5 0 0 1-1.953.145l-.168-.145a1.5 1.5 0 0 1 0-2.121l4-4 .144-.127.013-.009.064-.042.083-.057.019-.009.029-.018.059-.026.066-.034.044-.016.025-.011.037-.011.079-.026.055-.012.032-.009.038-.005.07-.014.054-.004.045-.006h.047L7 2.5l.058.004h.047l.041.006z",
  "arrow-top":
    "M11 19a1 1 0 1 0 2 0V7.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-7-7a1 1 0 0 0-1.414 0l-7 7a1 1 0 0 0 1.414 1.414L11 7.414V19z",
  "arrow-up-right":
    "M17 5l.066.004.052-.001.052.007.063.003.063.013.057.005.047.013.059.009.071.023.054.011.038.015.053.015.065.029.068.024.036.02.036.013.086.049.057.029.017.013.032.017.116.085.038.023.006.009.019.011a2.01 2.01 0 0 1 .31.31l.011.019.032.044.085.116.017.032.013.017.029.057.049.086.013.036.02.036.024.068.029.065.015.053.015.038.011.054.023.071.009.059.013.047.005.057.013.063.003.063.007.052-.001.052L19 7v10a2 2 0 1 1-4 0v-5.172l-6.586 6.586a2 2 0 0 1-2.64.166l-.188-.166a2 2 0 0 1 0-2.828L12.17 9H7a2 2 0 0 1-1.987-1.767L5 7a2 2 0 0 1 2-2h10z",
  "arrow-up-right-long":
    "M10 5a1 1 0 1 0 0 2h5.586L5.293 17.293a1 1 0 0 0 1.414 1.414L17 8.414V14a1 1 0 1 0 2 0V6a1 1 0 0 0-1-1h-8z",
  "arrow-up-right-thin":
    "M17 6l.033.002h.026l.026.003.032.002.031.006.028.003.024.006.029.004.036.012.027.005.019.008.026.007.033.015.034.012.018.01.018.007.043.024.028.014.009.007.016.009.058.042.019.012.003.004.009.006c.057.046.109.098.155.155l.006.009.016.022.042.058.009.016.007.009.014.028.024.043.007.018.01.018.012.034.015.033.007.026.008.019.005.027.012.036.004.029.006.024.003.028.006.031.002.032.003.026v.026L18 7v10a1 1 0 1 1-2 0V9.414l-8.293 8.293a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1 0-1.414L14.585 8H7a1 1 0 0 1-.993-.883L6 7a1 1 0 0 1 1-1h10z",
  arrows:
    "M6.293 14.293a1 1 0 0 1 1.414 0L12 18.585l4.293-4.292a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414zm5-11a1 1 0 0 1 1.414 0l5 5a1 1 0 0 1-1.414 1.414L12 5.415 7.707 9.707a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1 0-1.414l5-5z",
  check:
    "M18.929 5.434a1.5 1.5 0 0 1 2.246 1.98l-.125.141-11 11a1.5 1.5 0 0 1-1.98.125l-.141-.125-5-5a1.5 1.5 0 0 1 1.98-2.246l.141.125 3.939 3.939 9.94-9.939z",
  "check-circle":
    "M12.008 1.008c6.075 0 11 4.925 11 11s-4.925 11-11 11-11-4.925-11-11 4.925-11 11-11zm3.793 7.293l-5.293 5.293-2.293-2.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l6-6a1 1 0 1 0-1.414-1.414z",
  "check-thin":
    "M19.159 5.643a1.2 1.2 0 0 1 1.797 1.584l-.1.113-11 11a1.2 1.2 0 0 1-1.584.1l-.113-.1-5-5a1.2 1.2 0 0 1 1.584-1.797l.113.1 4.151 4.151L19.159 5.643z",
  close:
    "M4.939 4.939a1.5 1.5 0 0 1 2.121 0L12 9.878l4.939-4.938a1.5 1.5 0 0 1 1.98-.125l.141.125a1.5 1.5 0 0 1 0 2.121L14.121 12l4.94 4.939a1.5 1.5 0 0 1 .125 1.98l-.125.141a1.5 1.5 0 0 1-2.121 0L12 14.121l-4.939 4.94a1.5 1.5 0 0 1-1.98.125l-.141-.125a1.5 1.5 0 0 1 0-2.121L9.878 12 4.939 7.061a1.5 1.5 0 0 1-.125-1.98l.125-.141z",
  "close-circle":
    "M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm3.707 7.293a1 1 0 0 1 0 1.414L13.414 12l2.293 2.293a1 1 0 0 1-1.414 1.414L12 13.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L10.586 12 8.293 9.707a1 1 0 1 1 1.414-1.414L12 10.586l2.293-2.293a1 1 0 0 1 1.414 0z",
  copy: "M18.792.792c1.641 0 2.031.032 2.653.349a3.2 3.2 0 0 1 1.398 1.398c.317.622.349 1.011.349 2.653v7.877c-.004 1.411-.05 1.79-.348 2.376a3.2 3.2 0 0 1-1.398 1.398c-.622.317-1.012.349-2.653.349l-1.601-.001v1.878c-.004 1.323-.045 1.739-.295 2.268l-.053.108a3.2 3.2 0 0 1-1.398 1.398c-.622.317-1.012.349-2.653.349H4.915c-1.411-.004-1.79-.05-2.376-.348a3.2 3.2 0 0 1-1.398-1.398c-.317-.622-.349-1.011-.349-2.653v-7.6c0-1.641.032-2.031.349-2.653a3.2 3.2 0 0 1 1.398-1.398c.622-.317 1.011-.349 2.653-.349h1.6V4.915c.004-1.323.045-1.739.295-2.268l.053-.108a3.2 3.2 0 0 1 1.398-1.398C9.161.824 9.551.792 11.192.792h7.6zm-5.78 8.4h-8.04c-.975.003-1.225.027-1.343.087a.8.8 0 0 0-.35.35c-.065.127-.087.404-.087 1.563v7.82c.003.975.027 1.225.087 1.343a.8.8 0 0 0 .35.35c.127.064.404.087 1.563.087h7.82c.975-.003 1.225-.027 1.343-.087a.8.8 0 0 0 .35-.35c.064-.127.087-.404.087-1.563v-7.82c-.003-.975-.027-1.225-.087-1.343a.8.8 0 0 0-.349-.35c-.127-.065-.404-.087-1.563-.087h.22zm6-6h-8.04c-.975.003-1.225.027-1.343.087a.8.8 0 0 0-.35.35c-.065.127-.087.404-.087 1.563v1.6h3.877c1.323.004 1.739.045 2.268.295l.108.053a3.2 3.2 0 0 1 1.398 1.398c.317.622.349 1.011.349 2.653l-.001 3.599 1.821.001c.905-.003 1.185-.023 1.315-.075l.028-.012a.8.8 0 0 0 .35-.35c.064-.127.087-.404.087-1.563v-7.82c-.003-.975-.027-1.225-.087-1.343a.8.8 0 0 0-.349-.35c-.127-.065-.404-.087-1.563-.087h.22z",
  "copy-1":
    "M14.577 5.3c1.411.004 1.79.05 2.376.348a3.2 3.2 0 0 1 1.399 1.399c.317.622.349 1.011.349 2.653v8.1c0 1.641-.032 2.031-.349 2.653a3.2 3.2 0 0 1-1.398 1.398c-.622.317-1.011.349-2.653.349H6.2c-1.641 0-2.031-.032-2.653-.349a3.2 3.2 0 0 1-1.399-1.398c-.317-.622-.349-1.011-.349-2.653V9.7c0-1.641.032-2.031.349-2.653a3.2 3.2 0 0 1 1.398-1.398C4.169 5.332 4.559 5.3 6.2 5.3h8.377zm-.057 2.4H5.98c-.975.003-1.225.027-1.343.087a.8.8 0 0 0-.35.35c-.065.127-.087.404-.087 1.563v8.32c.003.975.027 1.225.087 1.343a.8.8 0 0 0 .35.35c.127.064.404.087 1.563.087h8.1c1.159 0 1.436-.023 1.563-.087a.8.8 0 0 0 .349-.349c.064-.127.087-.404.087-1.563V9.48c-.003-.975-.027-1.225-.087-1.343a.8.8 0 0 0-.35-.35c-.127-.064-.404-.087-1.563-.087h.22zm.08-5.9c3.041 0 3.764.059 4.761.567a5.2 5.2 0 0 1 2.272 2.272c.508.996.567 1.719.567 4.761v7.1a1.2 1.2 0 0 1-2.4 0V9.4c0-2.559-.05-3.17-.305-3.671a2.8 2.8 0 0 0-1.224-1.224c-.459-.234-1.011-.295-3.071-.304L7.5 4.2a1.2 1.2 0 1 1 0-2.4h7.1z",
  "credit-card-search":
    "M18 12a4.5 4.5 0 0 1 4.5 4.5 4.48 4.48 0 0 1-.648 2.328l-.043.066.898.899a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.414 0l-.899-.898-.066.043c-.623.377-1.344.608-2.116.643L18 21a4.5 4.5 0 1 1 0-9zm0 2a2.5 2.5 0 1 0 0 5 2.5 2.5 0 1 0 0-5zm.8-11.5c1.601 0 1.981.031 2.562.327a3 3 0 0 1 1.311 1.311c.296.581.327.961.327 2.562v3.8a1 1 0 1 1-2 0v-1H3v5.002c.003.966.025 1.258.094 1.421l.014.031a1 1 0 0 0 .437.437c.168.086.455.109 1.654.109h5.3a1 1 0 1 1 0 2H5.2c-1.601 0-1.981-.031-2.562-.327a3 3 0 0 1-1.311-1.311C1.031 16.281 1 15.901 1 14.3V6.7c0-1.601.031-1.981.327-2.562a3 3 0 0 1 1.311-1.311c.581-.296.961-.327 2.562-.327h13.6zm.202 2H4.998c-1.03.003-1.294.028-1.452.109a1 1 0 0 0-.437.437C3.023 5.214 3 5.501 3 6.7v.8h18v-.8c0-1.129-.021-1.449-.095-1.623l-.014-.031a1 1 0 0 0-.437-.437c-.168-.086-.455-.109-1.654-.109h.202z",
  envelope:
    "M17.2 3c2.301 0 2.848.045 3.616.436a4 4 0 0 1 1.748 1.748c.228.448.339.821.391 1.51v.016l.016.051a1 1 0 0 1 .022.354l-.012.067L23 8.521V8.8v6.4c0 2.301-.045 2.848-.436 3.616a4 4 0 0 1-1.748 1.748c-.768.391-1.315.436-3.616.436H6.8c-2.301 0-2.848-.045-3.616-.436a4 4 0 0 1-1.748-1.748C1.045 18.048 1 17.501 1 15.2V8.8l.019-1.615v-.003l-.012-.067a1 1 0 0 1 .022-.354l.016-.051v-.016c.049-.64.147-1.007.344-1.415l.047-.095a4 4 0 0 1 1.748-1.748C3.952 3.045 4.499 3 6.8 3h10.4zM21 8.92l-6.591 4.615c-.948.664-1.197.814-1.683.935a3 3 0 0 1-1.451 0c-.486-.121-.735-.271-1.683-.935L3 8.92l.001 6.744c.006 1.442.048 1.879.194 2.197l.023.047a2 2 0 0 0 .874.874c.355.181.809.218 2.708.218h10.4c1.899 0 2.353-.037 2.708-.218a2 2 0 0 0 .874-.874c.181-.355.218-.809.218-2.708V8.92zM6.336 5.001c-1.514.007-1.92.052-2.244.217a2 2 0 0 0-.874.874c-.055.108-.097.225-.128.39l-.01.053 7.658 5.361.989.624.031.009a1 1 0 0 0 .484 0c.143-.036.315-.14 1.02-.633l7.657-5.361-.009-.053c-.027-.141-.061-.247-.105-.343l-.023-.047a2 2 0 0 0-.874-.874c-.324-.165-.731-.21-2.245-.217H6.336z",
  "external-link":
    "M10 3.5a1.5 1.5 0 1 1 0 3H7.497c-1.545.004-1.941.042-2.178.163a1.5 1.5 0 0 0-.656.656c-.128.252-.163.682-.163 2.481v6.703c.004 1.545.042 1.941.163 2.178a1.5 1.5 0 0 0 .656.656c.252.128.682.163 2.481.163h6.4c1.799 0 2.229-.035 2.481-.164a1.5 1.5 0 0 0 .655-.655c.128-.252.163-.682.163-2.481V14a1.5 1.5 0 1 1 3 0v2.564c-.006 2.098-.07 2.653-.49 3.479a4.5 4.5 0 0 1-1.967 1.966c-.871.444-1.441.491-3.843.491H7.8c-2.402 0-2.972-.047-3.843-.49a4.5 4.5 0 0 1-1.967-1.967c-.444-.871-.49-1.441-.49-3.843V9.8c0-2.402.047-2.972.49-3.843A4.5 4.5 0 0 1 3.957 3.99c.871-.444 1.441-.49 3.843-.49H10zm4.061 8.561a1.5 1.5 0 1 1-2.121-2.121L17.377 4.5H15a1.5 1.5 0 0 1-1.49-1.325L13.5 3A1.5 1.5 0 0 1 15 1.5h6l.049.003h.039l.039.005.047.003.047.009.043.004.035.01.044.007.054.017.04.008.029.012.039.011.049.022.051.018.027.015.027.01.065.037.043.022.013.01.024.013.087.063.028.018.019.015a1.51 1.51 0 0 1 .232.232l.009.014.024.033.063.087.013.024.01.013.022.043.037.065.01.027.015.027.018.051.022.049.011.039.012.029.008.04.017.054.007.044.01.035.004.043.009.047.003.047.005.039v.039L22.5 3v6a1.5 1.5 0 1 1-3 0V6.621l-5.439 5.44z",
  home: "M11.477 1.7a2 2 0 0 1 1.045 0c.355.096.506.197 1.074.638l6.782 5.275.857.731c.064.069.122.14.177.214a3 3 0 0 1 .477.975c.1.357.111.57.111 1.395v7.235c0 1.601-.031 1.981-.327 2.562a3 3 0 0 1-1.311 1.311c-.581.296-.961.327-2.562.327H6.2c-1.601 0-1.981-.031-2.562-.327a3 3 0 0 1-1.311-1.311C2.031 20.144 2 19.764 2 18.163v-7.235c0-.826.011-1.039.111-1.396a3 3 0 0 1 .477-.975c.22-.298.382-.438 1.033-.944l6.782-5.275c.567-.441.718-.542 1.074-.638zm.478 1.969l-.324.248-6.782 5.275-.653.555a1 1 0 0 0-.159.325c-.025.088-.034.2-.036.588v7.705c.003 1.03.028 1.294.109 1.452a1 1 0 0 0 .437.437c.168.086.455.109 1.654.109h11.6c1.199 0 1.486-.023 1.654-.109a1 1 0 0 0 .437-.437c.086-.168.109-.455.109-1.654v-7.235l-.037-.856a1 1 0 0 0-.159-.325.55.55 0 0 0-.039-.046c-.081-.088-.197-.184-.615-.509l-6.782-5.275-.323-.248-.041-.032-.049.032z",
  info: "M11.993 2.66c5.155 0 9.333 4.179 9.333 9.333s-4.179 9.333-9.333 9.333-9.333-4.179-9.333-9.333S6.839 2.66 11.993 2.66zm0 2c-4.05 0-7.333 3.283-7.333 7.333s3.283 7.333 7.333 7.333 7.333-3.283 7.333-7.333-3.283-7.333-7.333-7.333zm0 6.333a1 1 0 0 1 1 1v3.333a1 1 0 1 1-2 0v-3.333a1 1 0 0 1 1-1zm-.71-3.37a1 1 0 0 1 1.414 1.414 1 1 0 0 1-1.414-1.414z",
  logout:
    "M6.234 2.386c.496.02 1.101.087 1.837.169l1.101.122.608.082a4 4 0 0 1 3.205 3.581c.015.172.015.36.015.614v.043a1 1 0 1 1-2 0v-.108l-.001-.164-.007-.213A2 2 0 0 0 9.39 4.721a3.68 3.68 0 0 0-.285-.039l-.197-.022-1.021-.113-1.732-.162c-.408-.016-.619.014-.764.06a2 2 0 0 0-1.216 1.089c-.062.139-.115.345-.144.753L4 7.536v8.922l.03 1.25c.029.407.082.614.144.753a2 2 0 0 0 1.216 1.089c.145.046.356.076.764.06.416-.016.95-.075 1.732-.162l1.021-.113.197-.022.285-.039a2 2 0 0 0 1.603-1.791 2.76 2.76 0 0 0 .007-.213l.001-.164v-.108a1 1 0 1 1 2 0v.043l-.015.614a4 4 0 0 1-3.205 3.581c-.169.034-.356.054-.608.082l-1.1.122-1.837.169c-.511.02-.989-.005-1.453-.154a4 4 0 0 1-2.433-2.177c-.199-.445-.277-.917-.313-1.427l-.034-1.241V7.386l.034-1.241c.036-.51.114-.982.313-1.427A4 4 0 0 1 4.781 2.54c.465-.149.942-.174 1.453-.154zM16.707 6.29l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414-1.414l3.293-3.293H9a1 1 0 1 1 0-2h9.586l-3.293-3.293a1 1 0 0 1 1.414-1.414z",
  moon: "M7.744 1.089c.84-.38 1.703.483 1.323 1.323-.548 1.213-.836 2.532-.836 3.894 0 5.226 4.236 9.462 9.462 9.462 1.362 0 2.682-.287 3.894-.836.84-.38 1.703.483 1.323 1.323C21.07 20.328 17.009 23 12.462 23 6.132 23 1 17.868 1 11.538 1 6.991 3.672 2.93 7.744 1.089zm-1.331 3.17l-.078.066C4.263 6.086 3 8.697 3 11.538 3 16.764 7.236 21 12.462 21c2.841 0 5.452-1.263 7.213-3.335l.065-.079-.017.004a11.5 11.5 0 0 1-1.618.172l-.411.007c-6.33 0-11.462-5.132-11.462-11.462a11.53 11.53 0 0 1 .179-2.029l.003-.018z",
  news: "M12.224 1.36c.639-.767 1.884-.227 1.761.764L13.125 9h6.345c.82.002 1.003.029 1.326.277l.072.057a1.5 1.5 0 0 1 .557 1.19c-.008.51-.089.627-.758 1.429L11.761 22.64c-.639.767-1.884.227-1.76-.764L10.859 15H4.701c-.98 0-1.165-.011-1.512-.277l-.072-.057a1.5 1.5 0 0 1-.557-1.19c.008-.51.089-.627.758-1.429L12.224 1.36zm-.647 3.9L5.127 13h6.865a1 1 0 0 1 1 1.006l-.008.118-.578 4.615L18.856 11h-6.864a1 1 0 0 1-1-1.006L11 9.876l.577-4.616z",
  notification:
    "M13.806 20.5a1 1 0 1 1 0 2h-4a1 1 0 1 1 0-2h4zm-2-19a7 7 0 0 1 7 7c0 2.565.556 4.562 1.499 6.077 1.392 2.237 1.277 2.038 1.236 2.545-.038.469-.223.802-.601 1.083-.348.258-.29.291-1.692.295l-.382.001h-.449H4.962h-.216l-.382-.001c-1.402-.004-1.344-.037-1.692-.295-.378-.281-.563-.613-.601-1.082-.041-.507-.155-.31 1.236-2.545.943-1.515 1.499-3.512 1.499-6.077a7 7 0 0 1 7-7zm0 2a5 5 0 0 0-5 5c0 2.934-.659 5.297-1.801 7.134l-.507.817-.029.047.08.001.455.001h.191 13.222.191l.455-.001.079-.001-.054-.089-.379-.612-.102-.164c-1.143-1.836-1.801-4.2-1.801-7.134a5 5 0 0 0-5-5z",
  password:
    "M17.5 11.5a2.75 2.75 0 0 1 2.75 2.75v.773l.044.004c.208.024.355.067.526.147l.088.043a2 2 0 0 1 .874.874c.201.394.218.607.218 1.508v.8c0 .901-.017 1.114-.218 1.508a2 2 0 0 1-.874.874c-.394.201-.607.218-1.508.218h-3.8c-.901 0-1.114-.017-1.508-.218a2 2 0 0 1-.874-.874c-.201-.394-.218-.607-.218-1.508v-.996c.003-.739.032-.949.218-1.312a2 2 0 0 1 .874-.874 1.62 1.62 0 0 1 .615-.191l.044-.004v-.773a2.75 2.75 0 0 1 2.582-2.745l.168-.005zm2.136 5.501h-4.272l-.359.003c.001.026-.002.125-.004.36v1.272l.003.359-.001.001c.03-.002.156.003.47.004h.127 3.8l.595-.004h.001c-.002-.029.003-.155.004-.469V18.4v-.8l-.004-.595c-.026.001-.125-.002-.36-.004zM17.5 13.5a.75.75 0 0 0-.75.75V15h1.5v-.75a.75.75 0 0 0-.648-.743L17.5 13.5zM18.8 3c1.601 0 1.981.031 2.562.327a3 3 0 0 1 1.311 1.311c.296.581.327.961.327 2.562V10a1 1 0 1 1-2 0V7.2c0-1.199-.023-1.486-.109-1.654a1 1 0 0 0-.437-.437C20.286 5.023 19.999 5 18.8 5H4.998c-1.03.003-1.294.028-1.452.109a1 1 0 0 0-.437.437C3.023 5.714 3 6.001 3 7.2v3.802c.003 1.03.028 1.294.109 1.452a1 1 0 0 0 .437.437c.168.086.455.109 1.654.109H11a1 1 0 1 1 0 2H5.2c-1.601 0-1.981-.031-2.562-.327a3 3 0 0 1-1.311-1.311C1.031 12.781 1 12.401 1 10.8V7.2c0-1.601.031-1.981.327-2.562a3 3 0 0 1 1.311-1.311C3.219 3.031 3.599 3 5.2 3h13.6zM12 7.75a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 1 1 0-2.5zm5 0a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 1 1 0-2.5zm-10 0a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 1 1 0-2.5z",
  plus: "M13 5a1 1 0 1 0-2 0v6H5a1 1 0 1 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2h-6V5z",
  refresh:
    "M20.439 11.687l.14.007a1.2 1.2 0 0 1 1.066 1.32c-.326 3.063-2.078 5.811-4.797 7.381-4.094 2.363-9.194 1.404-12.177-2.042l-.012-.015-.007.033a1.2 1.2 0 0 1-1.332.877l-.137-.028a1.2 1.2 0 0 1-.849-1.47l.732-2.732a1.2 1.2 0 0 1 1.47-.849l2.732.732a1.2 1.2 0 0 1-.212 2.355l-.115.002.131.124c2.219 2.03 5.548 2.536 8.326 1.072l.251-.139c2.048-1.182 3.365-3.248 3.61-5.556a1.2 1.2 0 0 1 1.32-1.066l-.14-.007zM7.149 3.593c4.094-2.363 9.194-1.404 12.177 2.042l.011.014.009-.031a1.2 1.2 0 0 1 1.332-.877l.137.028a1.2 1.2 0 0 1 .849 1.47l-.732 2.732a1.2 1.2 0 0 1-1.47.849l-2.732-.732a1.2 1.2 0 0 1 .212-2.355l.113-.004-.13-.123c-2.219-2.03-5.548-2.536-8.326-1.072l-.251.139c-2.048 1.182-3.365 3.248-3.61 5.556a1.2 1.2 0 0 1-2.387-.254c.326-3.063 2.078-5.811 4.797-7.381z",
  search:
    "M11 2a9 9 0 0 1 9 9 8.96 8.96 0 0 1-1.825 5.434l-.143.183 3.675 3.676a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.414 0l-3.676-3.675-.183.143c-1.444 1.096-3.23 1.765-5.169 1.821L11 20a9 9 0 1 1 0-18zm0 2a7 7 0 1 0 0 14c1.852 0 3.537-.72 4.789-1.894l.075-.075.079-.088.089-.079.074-.075c1.124-1.198 1.831-2.791 1.89-4.548L18 11a7 7 0 0 0-7-7z",
  settings:
    "M6 12c1.807 0 3.335 1.199 3.831 2.845l.042.155H21a1 1 0 0 1 .993.883L22 16a1 1 0 0 1-1 1H9.873l-.042.155c-.477 1.585-1.911 2.755-3.631 2.84L6 20a4 4 0 1 1 0-8zm0 2a2 2 0 1 0 0 4 2 2 0 1 0 0-4zM18 4a4 4 0 1 1 0 8c-1.808 0-3.335-1.199-3.831-2.845L14.126 9H3a1 1 0 0 1-.993-.883L2 8a1 1 0 0 1 1-1h11.126l.043-.155c.477-1.585 1.911-2.755 3.631-2.84L18 4zm0 2a2 2 0 1 0 0 4 2 2 0 1 0 0-4z",
  share:
    "M18 .5a4.5 4.5 0 1 1 0 9 4.48 4.48 0 0 1-2.815-.989l-.094-.079-4.672 2.726.014.062a4.51 4.51 0 0 1 .06.516L10.5 12a4.53 4.53 0 0 1-.067.781l-.012.06 4.674 2.724.091-.076a4.48 4.48 0 0 1 2.55-.981L18 14.5a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1-4.5-4.5 4.53 4.53 0 0 1 .067-.781l.012-.066-4.671-2.723-.094.08a4.48 4.48 0 0 1-2.55.981L6 16.5a4.5 4.5 0 1 1 0-9 4.48 4.48 0 0 1 2.815.989l.092.078 4.671-2.727-.011-.059a4.51 4.51 0 0 1-.06-.516L13.5 5A4.5 4.5 0 0 1 18 .5zm0 17c-.473 0-.896.219-1.17.562l-.069.095-.045.089-.066.099-.063.15a1.49 1.49 0 0 0-.077.331L16.5 19a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-1.5-1.5zm-12-7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 1.266-.696l.019-.036.008-.014.005-.009.049-.086a1.49 1.49 0 0 0 .142-.485L7.5 12c0-.237-.055-.461-.152-.66l-.044-.079-.009-.015-.015-.027-.013-.023a1.5 1.5 0 0 0-1.092-.686L6 10.5zm12-7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 1 0 0-3z",
  star: "M11.067 1.641c.329-.855 1.538-.855 1.867 0l2.012 5.231.524 1.188a2 2 0 0 0 .471.471 1.61 1.61 0 0 0 .228.132c.176.086.361.161.959.392l5.231 2.012c.855.329.855 1.538 0 1.867l-5.231 2.012-1.188.524c-.182.13-.342.289-.471.471-.123.173-.207.363-.524 1.188l-2.012 5.231c-.329.855-1.538.855-1.867 0l-2.012-5.231-.392-.96a1.61 1.61 0 0 0-.132-.228 2 2 0 0 0-.471-.471c-.172-.123-.363-.207-1.188-.524l-5.231-2.012c-.855-.329-.855-1.538 0-1.867l5.231-2.012.96-.392a1.61 1.61 0 0 0 .228-.132 2 2 0 0 0 .471-.471 1.61 1.61 0 0 0 .132-.228c.086-.176.161-.361.392-.96l2.012-5.231zM12 4.785L10.921 7.59l-.463 1.124a3.58 3.58 0 0 1-.297.505 4 4 0 0 1-.942.942 3.58 3.58 0 0 1-.505.297c-.253.124-.459.208-1.124.463L4.785 12l2.805 1.079c.941.362 1.224.483 1.557.71l.072.05a4 4 0 0 1 .942.942 3.58 3.58 0 0 1 .297.505c.124.253.208.459.463 1.124L12 19.214l1.079-2.804c.362-.941.483-1.224.71-1.557l.05-.072c.259-.364.577-.683.942-.942s.62-.373 1.629-.76L19.214 12l-2.804-1.079-1.056-.43-.068-.033a3.58 3.58 0 0 1-.505-.297 4 4 0 0 1-.942-.942c-.261-.367-.373-.62-.76-1.629L12 4.785z",
  "star-plus":
    "M4.5 16a1 1 0 0 1 1 1v1.5H7a1 1 0 0 1 .993.883L8 19.5a1 1 0 0 1-1 1H5.5V22a1 1 0 0 1-.883.993L4.5 23a1 1 0 0 1-1-1v-1.5H2a1 1 0 0 1-.993-.883L1 19.5a1 1 0 0 1 1-1h1.5V17a1 1 0 0 1 .883-.993L4.5 16zm7.567-13.359c.329-.855 1.538-.855 1.867 0l1.734 4.509.391.959a1.61 1.61 0 0 0 .133.228 2 2 0 0 0 .471.471c.172.123.363.207 1.188.524l4.509 1.734c.855.329.855 1.538 0 1.867l-4.509 1.734-1.188.524a2 2 0 0 0-.471.471 1.61 1.61 0 0 0-.132.228c-.086.176-.161.361-.391.959l-1.734 4.509c-.329.855-1.538.855-1.867 0l-1.734-4.509-.524-1.188a2 2 0 0 0-.471-.471 1.61 1.61 0 0 0-.228-.132c-.176-.086-.361-.161-.959-.391l-4.509-1.734c-.855-.329-.855-1.538 0-1.867L8.15 9.332l1.188-.524a2 2 0 0 0 .471-.471 1.61 1.61 0 0 0 .132-.228c.086-.176.161-.361.391-.959l1.734-4.509zM13 5.785l-.801 2.083-.463 1.124a3.58 3.58 0 0 1-.297.505 4 4 0 0 1-.942.942c-.367.261-.62.373-1.629.761L6.785 12l2.083.801 1.056.43.068.033a3.58 3.58 0 0 1 .505.297 4 4 0 0 1 .942.942c.261.367.373.62.761 1.629L13 18.214l.801-2.082.43-1.056.033-.068a3.58 3.58 0 0 1 .297-.505 4 4 0 0 1 .942-.942c.367-.261.62-.373 1.629-.761L19.214 12l-2.082-.801c-.941-.362-1.224-.483-1.557-.71l-.072-.05a4 4 0 0 1-.942-.942 3.58 3.58 0 0 1-.297-.505c-.124-.253-.208-.459-.463-1.124L13 5.785zM4.5 1a1 1 0 0 1 1 1v1.5H7a1 1 0 0 1 .993.883L8 4.5a1 1 0 0 1-1 1H5.5V7a1 1 0 0 1-.883.993L4.5 8a1 1 0 0 1-1-1V5.5H2a1 1 0 0 1-.993-.883L1 4.5a1 1 0 0 1 1-1h1.5V2a1 1 0 0 1 .883-.993L4.5 1z",
  sun: "M12 19a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1zm-6.393-2.017a1 1 0 0 1 1.414 1.414l-1.414 1.414a1 1 0 1 1-1.414-1.414l1.414-1.414zm11.372 0a1 1 0 0 1 1.414 0l1.414 1.414a1 1 0 1 1-1.414 1.414l-1.414-1.414a1 1 0 0 1 0-1.414zM12 6a6 6 0 1 1 0 12 6 6 0 1 1 0-12zm0 2a4 4 0 1 0 0 8 4 4 0 1 0 0-8zm-8 3a1 1 0 1 1 0 2H2a1 1 0 1 1 0-2h2zm18 0a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2h2zM4.193 4.193a1 1 0 0 1 1.414 0l1.414 1.414a1 1 0 0 1-1.414 1.414L4.193 5.607a1 1 0 0 1 0-1.414zm14.2 0a1 1 0 1 1 1.414 1.414l-1.414 1.414a1 1 0 0 1-1.414-1.414l1.414-1.414zM12 1a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1z",
  support:
    "M16.2 2c2.301 0 2.848.045 3.616.436a4 4 0 0 1 1.748 1.748c.391.768.436 1.315.436 3.616v8.4c0 2.301-.045 2.848-.436 3.616a4 4 0 0 1-1.748 1.748c-.768.391-1.315.436-3.616.436H7.8c-2.301 0-2.848-.045-3.616-.436a4 4 0 0 1-1.748-1.748C2.045 19.048 2 18.501 2 16.2V7.8c0-2.301.045-2.848.436-3.616a4 4 0 0 1 1.748-1.748C4.952 2.045 5.499 2 7.8 2h8.4zM7.336 4.001c-1.514.007-1.92.052-2.244.217a2 2 0 0 0-.874.874c-.165.324-.21.731-.217 2.244v9.327c.007 1.514.052 1.92.217 2.244a2 2 0 0 0 .874.874c.355.181.809.218 2.708.218h8.4c1.899 0 2.353-.037 2.708-.218a2 2 0 0 0 .874-.874c.181-.355.218-.809.218-2.708V7.8c0-1.899-.037-2.353-.218-2.708a2 2 0 0 0-.874-.874c-.324-.165-.731-.21-2.245-.217H7.336zM12 16a1 1 0 1 1 0 2 1 1 0 1 1 0-2zM9.893 6.547a4 4 0 0 1 5.087.873 4 4 0 0 1 .94 2.58c0 1.28-.758 2.291-1.945 3.082-.649.433-1.286.716-1.738.867a1 1 0 1 1-.632-1.897c.065-.022.199-.073.379-.153.303-.135.606-.296.883-.481.688-.459 1.055-.948 1.055-1.419a2 2 0 0 0-1.662-1.974 2 2 0 0 0-2.225 1.308 1 1 0 1 1-1.887-.664 4 4 0 0 1 1.747-2.121z",
  toggle:
    "M18 4a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h12zM9 6H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h3V6zm9 0h-7v12h7a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2z",
  trade:
    "M11.712 22.996c-2.875-.074-5.476-1.251-7.394-3.124l-.08-.08-.015-.014-.015-.016-.079-.079c-1.873-1.919-3.05-4.519-3.124-7.394L1 12l.004-.288c.074-2.875 1.251-5.476 3.124-7.394l.079-.08.015-.015.015-.015.08-.079c1.919-1.873 4.519-3.05 7.394-3.124L12 1l.288.004c2.875.074 5.476 1.251 7.394 3.124l.079.079.016.015.014.015.08.08c1.873 1.919 3.05 4.519 3.124 7.394L23 12c0 2.989-1.192 5.7-3.128 7.683l-.08.079-.014.016-.016.014-.079.08c-1.919 1.873-4.519 3.05-7.394 3.124L12 23l-.288-.004zM13 3.055V4a1 1 0 1 1-2 0v-.945l-.05.006c-1.635.19-3.137.818-4.385 1.765l-.184.143.668.668a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.414 0l-.668-.668-.143.184c-.946 1.247-1.575 2.749-1.765 4.385l-.006.05H4a1 1 0 0 1 .993.883L5 12a1 1 0 0 1-1 1h-.945l.006.05c.19 1.635.818 3.137 1.765 4.385l.143.183.668-.667a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 0 1.414l-.668.668.184.143c1.247.946 2.749 1.575 4.385 1.765l.05.005V20a1 1 0 0 1 .883-.993L12 19a1 1 0 0 1 1 1v.944l.05-.005c1.635-.19 3.137-.818 4.385-1.765l.183-.143-.667-.668a1 1 0 0 1-.083-1.32l.083-.094a1 1 0 0 1 1.414 0l.668.667.143-.183c.946-1.247 1.575-2.749 1.765-4.385l.005-.05H20a1 1 0 0 1-.993-.883L19 12a1 1 0 0 1 1-1h.944l-.005-.05c-.19-1.635-.818-3.137-1.765-4.385l-.143-.184-.668.668a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1 0-1.414l.667-.668-.183-.143c-1.247-.946-2.749-1.575-4.385-1.765L13 3.055zm-1.857 4.431a1 1 0 0 1 1.715 0l1.371 2.285 2.286 1.372a1 1 0 0 1 .108 1.641l-.108.074-2.286 1.371-1.371 2.286a1 1 0 0 1-1.641.108l-.074-.108-1.372-2.286-2.285-1.371a1 1 0 0 1-.108-1.641l.108-.074 2.285-1.372 1.372-2.285zM12 9.943l-.643 1.071a1 1 0 0 1-.343.343L9.943 12l1.071.643a1 1 0 0 1 .274.242l.069.101.643 1.07.643-1.07a1 1 0 0 1 .242-.274l.101-.069 1.07-.643-1.07-.643a1 1 0 0 1-.274-.242l-.069-.101L12 9.943z",
  "triangle-down":
    "M12.705 15.493l5.586-5.586c.63-.63.184-1.707-.707-1.707H6.412c-.891 0-1.337 1.077-.707 1.707l5.586 5.586a1 1 0 0 0 1.414 0z",
  "triangle-up":
    "M11.291 8.493l-5.586 5.586c-.63.63-.184 1.707.707 1.707h11.172c.891 0 1.337-1.077.707-1.707l-5.586-5.586a1 1 0 0 0-1.414 0z",
  wallet:
    "M13.31 2.362c1.204-.556 1.457-.645 2.025-.549a2 2 0 0 1 1.314.841c.326.476.35.743.35 2.069v2.498l.8.001c1.517 0 1.938.028 2.472.282l.09.045a3 3 0 0 1 1.311 1.311c.296.581.327.961.327 2.562v6.6c0 1.601-.031 1.981-.327 2.562a3 3 0 0 1-1.311 1.311c-.581.296-.961.327-2.562.327H5.957c-1.399-.004-1.769-.046-2.319-.327a3 3 0 0 1-1.311-1.311C2.031 20.004 2 19.624 2 18.023v-6.6-1.152c0-1.065.017-1.333.173-1.773a3 3 0 0 1 .73-1.141 2.71 2.71 0 0 1 .482-.371c.237-.146.429-.24 1.054-.529l8.87-4.094zm4.49 6.86H6.2c-1.199 0-1.486.023-1.654.109a1 1 0 0 0-.437.437c-.086.168-.109.455-.109 1.654v6.802c.003 1.03.028 1.294.109 1.452a1 1 0 0 0 .437.437c.168.086.455.109 1.654.109h11.802c1.03-.003 1.294-.028 1.452-.109a1 1 0 0 0 .437-.437c.086-.168.109-.455.109-1.654v-6.6c0-1.199-.023-1.486-.109-1.654a1 1 0 0 0-.437-.437c-.168-.086-.455-.109-1.654-.109zm-1.3 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 1 1 0-3zm0 1a.5.5 0 1 0 0 1 .5.5 0 1 0 0-1zM14.987 3.8c-.062.033-.301.13-.838.378L7.553 7.222H15V4.723l-.008-.818v-.078-.024l.001-.008-.006.004z",
};

type IconProps = {
  className?: string;
  name: any;
  fill?: any;
};

const Icon = ({ className, name, fill }: IconProps) => (
  <svg
    className={`inline-flex w-6 h-6 ${className || ""}`}
    width={24}
    height={24}
    viewBox="0 0 24 24"
  >
    <path fill={fill} d={icons[name]}></path>
  </svg>
);

export default Icon;
