import { useEffect, useState } from "react";

interface Countdown {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  isReleased: boolean;
}

export function useCountdown(releaseDate?: string): Countdown {
  const [timeLeft, setTimeLeft] = useState<Countdown>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    isReleased: true,
  });

  useEffect(() => {
    if (!releaseDate) {
      setTimeLeft({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        isReleased: true,
      });
      return;
    }
    function calculateTimeLeft() {
      const target = new Date(releaseDate!).getTime();
      const now = Date.now();
      const difference = target - now;
      if (difference <= 0) {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          isReleased: true,
        };
      }
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      return {
        days,
        hours,
        minutes,
        seconds,
        isReleased: false,
      };
    }
    function update() {
      setTimeLeft(calculateTimeLeft());
    }
    update();
    const interval = setInterval(update, 1000);
    return () => clearInterval(interval);
  }, [releaseDate]);

  return timeLeft;
}
