import React from "react";
import clsx from "clsx";

interface WindowContentProps {
  children: React.ReactNode;
  isLoading?: boolean;
  windowType?: "iframe" | "component";
}

const WindowContent: React.FC<WindowContentProps> = ({
  children,
  isLoading,
  windowType = "component",
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col h-full w-full box-border",
        "bg-transparent rounded-2xl",
        "shadow-[0_4px_10px_rgba(0,0,0,0.3)] bg-opacity-100",
        "z-[5]",
        "overflow-hidden"
      )}
    >
      {windowType === "iframe" ? (
        <div className="flex-grow overflow-hidden w-full h-full box-border">
          {isLoading ? (
            <div className="w-full h-full rounded-2xl bg-gray-200" />
          ) : (
            children
          )}
        </div>
      ) : (
        <div className="flex-grow overflow-auto w-full h-full box-border">
          {children}
        </div>
      )}
    </div>
  );
};

export default WindowContent;
