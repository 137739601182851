import { useState } from "react";
import { useWindowResize } from "./useWindowResize";

export function useIsMobile(breakpoint = 768) {
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" ? window.innerWidth < breakpoint : false
  );

  useWindowResize((width, _height) => {
    setIsMobile(width < breakpoint);
  });

  return isMobile;
}
