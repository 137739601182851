import { makeAutoObservable } from "mobx";

export class SynkStoreLocalStore {
  instanceId: string;
  activeApp: any = null;

  constructor(instanceId: string) {
    makeAutoObservable(this);
    this.instanceId = instanceId;
  }

  setActiveApp(app: any) {
    this.activeApp = app;
  }

  closeActiveApp() {
    this.activeApp = null;
  }
}

export default SynkStoreLocalStore;
