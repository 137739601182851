import React from "react";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";

import { SynkStoreLocalStore } from "./store/SynkStoreLocalStore";

import appsData from "./data/appsData.json";
import ExpandableCardList from "./components/ExpandableCardList";
import ExpandedDetailModal from "./components/ExpandedDetailModal";

type SynkStoreProps = {
  instanceId?: string;
  localStore?: SynkStoreLocalStore;
};

const SynkStore: React.FC<SynkStoreProps> = observer(
  ({ instanceId, localStore }) => {
    if (!localStore) {
      return (
        <div className="text-red-500">No local store found for SynkStore</div>
      );
    }

    const { activeApp } = localStore;

    const handleSelectApp = (app: any) => {
      localStore.setActiveApp(app);
    };

    const handleClose = () => {
      localStore.closeActiveApp();
    };

    const storeItems = appsData.filter((x) => !x.hideFromStore);

    return (
      <div className="w-full min-h-full flex justify-center p-4 bg-black/[0.96] overflow-auto relative @container">
        <div className="absolute inset-0 h-full w-full bg-customGray/30 z-0"></div>
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808010_1px,transparent_1px),linear-gradient(to_bottom,#80808010_1px,transparent_1px)] bg-[size:150px_150px] pointer-events-none" />

        <div className="w-full flex-1">
          <motion.div className="py-4 @md:py-6 mx-auto px-4 @md:px-4">
            <ExpandableCardList
              apps={storeItems}
              onSelect={handleSelectApp}
              instanceId={instanceId}
            />
          </motion.div>

          <ExpandedDetailModal
            app={activeApp}
            onClose={handleClose}
            instanceId={instanceId}
          />
        </div>
      </div>
    );
  }
);

export default SynkStore;
