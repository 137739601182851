import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { OsynkLocalStore } from "locaLapps/Osynk/store/OsynkLocalStore";
import Question from "../Question";
import Answer from "../Answer";
import { Spotlight } from "components/UI/Spotlight";
import { HeroHighlight } from "components/UI/hero-highlight";

interface ConversationProps {
  localStore: OsynkLocalStore;
}

const MAX_CHARS = 600;

function getDynamicPadding(height: number): number {
  if (height <= 25) {
    return 0;
  } else if (height > 25 && height <= 48) {
    return 30;
  } else if (height > 48 && height <= 60) {
    return 40;
  } else if (height > 60 && height <= 80) {
    return 40;
  } else {
    return 40;
  }
}

const Conversation: React.FC<ConversationProps> = observer(({ localStore }) => {
  const { messages, loading, error, selectedCategory, searchValue } =
    localStore;

  const { draftMessage, setDraftMessage } = localStore;

  const [textareaHeight, setTextareaHeight] = useState(30);
  const [hasInitialLoad, setHasInitialLoad] = useState(true);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleSend = async (e: React.FormEvent) => {
    e.preventDefault();
    const trimmedMessage = draftMessage.trim();
    if (trimmedMessage) {
      await localStore.sendMessageStream(trimmedMessage);
      setDraftMessage("");
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "30px";
      const newHeight = Math.min(textareaRef.current.scrollHeight, 144);
      textareaRef.current.style.height = `${newHeight}px`;
      setTextareaHeight(newHeight);
    }
  }, [draftMessage]);

  const dynamicPadding = getDynamicPadding(textareaHeight);

  const handleNewChat = () => {
    localStore.clearMessages();
    localStore.setViewState("setup");
    setDraftMessage("");
  };

  const categoryLabel = selectedCategory
    ? selectedCategory === "email"
      ? "Email"
      : selectedCategory === "phone"
      ? "Phone"
      : "Username"
    : "";

  useEffect(() => {
    if (hasInitialLoad && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
      setHasInitialLoad(false);
    }
  }, [hasInitialLoad]);

  useEffect(() => {
    if (!hasInitialLoad && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [messages, hasInitialLoad]);

  useEffect(() => {
    if (!hasInitialLoad && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [messages.map((m) => m.content).join(""), hasInitialLoad]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = e.target.value.slice(0, MAX_CHARS);
    setDraftMessage(val);
  };

  return (
    <div className="relative flex flex-col items-center w-full h-full overflow-hidden bg-black/[0.96]">
      <div className="absolute inset-0 bg-customGray/30 z-0"></div>
      <div className="absolute inset-0 h-full w-full bg-[linear-gradient(to_right,#80808008_1px,transparent_1px),linear-gradient(to_bottom,#80808008_1px,transparent_1px)] bg-[size:150px_150px]"></div>

      <div className="flex items-center mb-6 c_md:mb-4 px-6 pt-6 w-full max-w-[1150px] z-[900]">
        <div className="text-3xl select-none font-bold bg-clip-text text-transparent bg-gradient-to-b from-neutral-600 to-neutral-200 inset-x-0 uppercase">
          OSynk
        </div>
        <button onClick={handleNewChat} className="ml-auto btn-secondary">
          New Chat
        </button>
      </div>

      <Spotlight
        className="-top-40 left-0 md:left-60 md:-top-20"
        fill="#22dcd1"
      />

      <div
        ref={scrollContainerRef}
        className="flex-grow overflow-auto px-12 py-4 flex flex-col items-center w-full relative z-[10]"
      >
        {messages.length === 0 ? (
          <div className="text-center text-theme-secondary">
            No messages yet.
          </div>
        ) : (
          <>
            {selectedCategory && searchValue && (
              <div className="flex flex-col gap-3 mt-3 relative transition-height h-auto w-full max-w-[1150px]">
                <div
                  className="px-4 shadow-medium rounded-medium bg-content1 w-full"
                  data-orientation="vertical"
                >
                  <div
                    data-open="true"
                    className="py-0 w-full text-sm"
                    aria-label="Ultra Mode"
                  >
                    <h2>
                      <button
                        className="w-full gap-3 items-center tap-highlight-transparent outline-none transition-opacity py-0 rounded-lg h-14 flex items-left justify-start"
                        type="button"
                        aria-expanded="false"
                      >
                        <div className="flex-shrink-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            fill="none"
                          >
                            <rect width="24" height="24"></rect>
                            <path
                              d="M17.3702 16.2713L20.465 15.2776C21.5834 14.9184 21.8652 13.4928 20.9647 12.749L18.5414 10.7472M17.3702 16.2713L15.6246 16.8319C14.8533 17.0796 14.1247 17.441 13.464 17.9038L12.1229 18.8432M17.3702 16.2713L17.4325 14.7768C17.4671 13.9453 17.636 13.1245 17.9329 12.345L18.5414 10.7472M17.3702 16.2713L17.2401 19.3942C17.193 20.5254 15.9184 21.1841 14.942 20.5819L12.1229 18.8432M12.1229 18.8432L9.43738 20.7242C8.50033 21.3805 7.19058 20.7997 7.07263 19.6754L6.74168 16.5208M12.1229 18.8432L10.5594 17.8788C9.87224 17.455 9.12342 17.1362 8.33865 16.9335L6.74168 16.5208M6.74168 16.5208L3.61989 15.7141C2.48271 15.4203 2.11569 14.0149 2.96854 13.22L5.2878 11.0584M6.74168 16.5208L6.43349 13.5832L5.2878 11.0584M5.2878 11.0584L4.00706 8.23589C3.53226 7.18955 4.40146 6.03466 5.55954 6.17312L8.85203 6.56678M5.2878 11.0584L6.58864 9.84595C7.1925 9.28313 7.70325 8.63126 8.10245 7.91385L8.85203 6.56678M8.85203 6.56678L10.4099 3.76716C10.9595 2.7795 12.3882 2.73674 12.998 3.68971L14.7505 6.42828M8.85203 6.56678L10.7141 6.78942C11.5126 6.88489 12.3213 6.86164 13.1127 6.72045L14.7505 6.42828M14.7505 6.42828L17.9828 5.85166C19.1288 5.64722 20.0649 6.74666 19.657 7.81789L18.5414 10.7472M14.7505 6.42828L15.6961 7.90606C16.1377 8.59606 16.6858 9.21445 17.3209 9.73905L18.5414 10.7472"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div className="flex-1 flex flex-col text-start">
                          <span className="font-medium text-default-500 text-medium">
                            {categoryLabel} : {searchValue}
                          </span>
                        </div>
                        <span
                          aria-hidden="true"
                          className="text-default-400 transition-transform rotate-0 text-medium"
                        >
                          <svg
                            aria-hidden="true"
                            fill="none"
                            focusable="false"
                            height="1em"
                            role="presentation"
                            viewBox="0 0 24 24"
                            width="1em"
                          >
                            <path
                              d="M15.5 19l-7-7 7-7"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            ></path>
                          </svg>
                        </span>
                      </button>
                    </h2>
                  </div>
                </div>
              </div>
            )}

            {messages
              .filter((msg) => msg.role !== "system")
              .map((msg, index) =>
                msg.role === "user" ? (
                  <Question key={index} content={msg.content} />
                ) : (
                  <Answer key={index} content={msg.content} />
                )
              )}
            {loading && (
              <div className="text-center text-gray-500">Thinking...</div>
            )}
            {error && <div className="text-red-500">{error}</div>}
          </>
        )}
      </div>

      <form
        onSubmit={handleSend}
        className="shrink-0 c_md:mt-4 px-6 pb-6 w-full flex justify-center relative z-30"
      >
        <div className="flex flex-col justify-start max-w-[750px] w-full">
          <div className="max-w-[750px] flex flex-col justify-start gap-2 relative w-full p-2 rounded-3xl border border-gray-200 dark:border-gray-800">
            <div className="absolute inset-0 overflow-hidden rounded-3xl border-none">
              <Spotlight className="!w-[1000px] !h-[900px] !left-[245px]" />
            </div>
            <div
              className="flex justify-start gap-2 w-full overflow-visible relative"
              // style={{ etc.  }}
            >
              <div
                className="w-full relative flex justify-between items-center py-3 px-1 bg-white dark:bg-zinc-800 rounded-2xl overflow-hidden shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),_0px_1px_0px_0px_rgba(25,28,33,0.02),_0px_0px_0px_1px_rgba(25,28,33,0.08)] transition duration-1000"
                style={{ paddingBottom: `${dynamicPadding}px` }}
              >
                <textarea
                  ref={textareaRef}
                  required
                  placeholder="Ask me anything..."
                  value={draftMessage}
                  onChange={handleInputChange}
                  className="w-full relative text-sm sm:text-base whitespace-pre-line resize-none z-50 border-none dark:text-white bg-transparent text-black rounded-xl focus:outline-none focus:ring-0 pl-3 pr-20 overflow-y-auto scrollbar-none"
                  disabled={loading}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSend(e);
                    }
                  }}
                />

                <button
                  type="submit"
                  className="absolute right-2 top-1/2 z-50 -translate-y-1/2 h-8 w-8 rounded-full bg-black dark:bg-zinc-900 transition duration-200 flex items-center justify-center disabled:bg-gray-300"
                  disabled={!draftMessage.trim() || loading}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="text-gray-100 h-4 w-4"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l14 0"></path>
                    <path d="M13 18l6 -6"></path>
                    <path d="M13 6l6 6"></path>
                  </svg>
                </button>

                <div className="absolute left-3 bottom-1 text-xs text-gray-500">
                  {draftMessage.length}/{MAX_CHARS}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
});

export default Conversation;
