import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import appStore from "stores/AppStore";
import NoVNCViewer from "components/NoVNCViewer/VncViewer";
import { Input, Button } from "@nextui-org/react";

interface OpenURLAppProps {
  appInstanceId: string;
}

const OpenURLApp: React.FC<OpenURLAppProps> = observer(({ appInstanceId }) => {
  const instance = appStore.runningApps.get(appInstanceId);
  const [hasChosenUrl, setHasChosenUrl] = useState(!!instance?.screenUrl);
  const [inputValue, setInputValue] = useState(instance?.screenUrl || "");
  const [error, setError] = useState<string | null>(null);

  const validateUrl = (value: string) => {
    if (value.length < 3) {
      return "URL must be at least 3 characters long";
    }
    if (
      !value.startsWith("wss://") &&
      !value.startsWith("ws://") &&
      !value.startsWith("https://") &&
      !value.startsWith("http://")
    ) {
      return "URL must start with ws(s):// or http(s)://";
    }
    return null;
  };

  const handleOpen = () => {
    const validationError = validateUrl(inputValue);
    if (validationError) {
      setError(validationError);
      return;
    }

    const inst = appStore.runningApps.get(appInstanceId);
    if (!inst) return;
    inst.screenUrl = inputValue.trim();
    setHasChosenUrl(true);
  };

  if (!instance) {
    return <div className="p-4">No instance found.</div>;
  }

  if (!hasChosenUrl) {
    return (
      <div
        className="h-full w-full flex items-center justify-center"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="w-full max-w-sm bg-white/10 backdrop-blur-md p-6 rounded-lg flex flex-col gap-4">
          <Input
            isRequired
            label="URL"
            labelPlacement="outside"
            placeholder="Enter a WS(S) or HTTP(S) URL"
            type="text"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              if (error) setError(null);
            }}
          />
          {error && <div className="text-red-500 text-sm">{error}</div>}
          <Button color="success" onClick={handleOpen}>
            Open
          </Button>
        </div>
      </div>
    );
  }

  const url = instance.screenUrl;
  if (!url) {
    return <div className="p-4">No URL provided.</div>;
  }

  if (url.startsWith("wss://")) {
    return (
      <div className="flex-1 p-1.5 pl-0 pt-0 h-full min-h-0">
        <div className="relative w-full h-full" data-testid="iframe-component">
          <NoVNCViewer
            localVncStore={instance.localStore}
            localId={instance.localInstanceId}
            url={url}
          />
        </div>
      </div>
    );
  } else {
    return (
      <iframe
        src={url}
        className="w-full h-full border-none"
        allow="camera; microphone; fullscreen"
      />
    );
  }
});

export default OpenURLApp;
