import React from "react";
import MessageGenericPage from "components/MessageGenericPage/MessageGenericPage";
import { OsynkLocalStore } from "locaLapps/Osynk/store/OsynkLocalStore";
import { observer } from "mobx-react-lite";

interface NoMoreProps {
  localStore: OsynkLocalStore;
}

const NoMore: React.FC<NoMoreProps> = observer(({ localStore }) => {
  return (
    <MessageGenericPage
      title={
        <>
          Yikes! <br /> That Didn't Go as Planned.
        </>
      }
      message="Looks like OSynk tripped over itself. Maybe it needs a coffee break? Try again in a bit or give support a shout if it keeps acting up!"
      buttonText="Back to OSynk"
      onButtonClick={() => {
        localStore.error = null;
        localStore.setViewState("setup");
      }}
    />
  );
});

export default NoMore;
