import React, { useState } from "react";
import {
  Button,
  Divider,
  Card,
  CardBody,
  CardFooter,
  Input,
  ScrollShadow,
  Tooltip,
  Slider,
} from "@nextui-org/react";
import {
  Video,
  VideoOff,
  Mic,
  MicOff,
  Volume2,
  VolumeX,
  Maximize,
  Minimize,
  ClipboardCheck,
  Sun,
  Power,
  Copy,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useIsDesktop } from "utils/useIsDesktop";
import { notificationManager } from "components/UI/notificationManager";
import { NoVncLocalStore } from "../store/NoVncLocalStore";

interface SidebarProps {
  localStore: NoVncLocalStore;
}

const Sidebar: React.FC<SidebarProps> = observer(({ localStore }) => {
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  const handleQualityLevel = (value: number | number[]) => {
    if (typeof value === "number") {
      localStore.setQualityLevel(value);
    } else {
      console.warn("Expected a single number for quality level.");
    }
  };

  const handleCompressionLevel = (value: number | number[]) => {
    if (typeof value === "number") {
      localStore.setCompressionLevel(value);
    } else {
      console.warn("Expected a single number for compression level.");
    }
  };

  const buttons = [
    {
      icon: localStore.isWebcamEnabled ? (
        <Video width={18} height={18} />
      ) : (
        <VideoOff width={18} height={18} />
      ),
      onClick: () => localStore.toggleWebcam(),
      tooltip: "Toggle Webcam",
      disabled: true,
    },
    {
      icon: localStore.isVolumeMuted ? (
        <VolumeX width={18} height={18} />
      ) : (
        <Volume2 width={18} height={18} />
      ),
      onClick: () => localStore.toggleVolume(),
      tooltip: "Toggle Sound",
      disabled: true,
    },
    {
      icon: localStore.isMicMuted ? (
        <MicOff width={18} height={18} />
      ) : (
        <Mic width={18} height={18} />
      ),
      onClick: () => localStore.toggleMic(),
      tooltip: "Toggle Microphone",
      disabled: true,
    },
    {
      icon: localStore.isFullscreen ? (
        <Minimize width={18} height={18} />
      ) : (
        <Maximize width={18} height={18} />
      ),
      onClick: () => {
        if (isDesktop) {
          localStore.toggleFullscreen();
        }
      },
      tooltip: localStore.isFullscreen ? "Exit Fullscreen" : "Fullscreen",
      disabled: true,
    },
  ];

  const handleCopyLink = () => {};

  const buttons2 = [
    {
      icon: <ClipboardCheck width={18} height={18} />,
      label: "Clipboard",
      onClick: () => {
        localStore.setClipboardModalOpen(true);
      },
      tooltip: "Access Clipboard",
      dataTestId: "sidebar-clipboard-button",
    },
    {
      icon: <Sun width={18} height={18} />,
      label: "Brightness",
      onClick: () => {
        localStore.setBrightnessModalOpen(true);
      },
      tooltip: "Adjust Brightness",
      disabled: true,
    },
  ];

  return (
    <div
      className="p-4 pt-0 pl-1.5 flex flex-col h-full max-h-full gap-5"
      data-testid="sidebar-component"
    >
      <ScrollShadow className="-mr-4 h-full max-h-full py-6 pt-0 pr-2">
        <div className="grid grid-cols-2 sm:grid-cols-2 gap-3">
          {buttons.map((button, index) => (
            <Tooltip
              key={index}
              content={button.tooltip}
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                onClick={button.onClick}
                radius="sm"
                variant="light"
                isIconOnly
                className="bg-customGray/20 h-12 w-full backdrop-blur-dock text-white"
                isDisabled={button.disabled}
              >
                {button.icon}
              </Button>
            </Tooltip>
          ))}
        </div>

        <Divider className="my-4" />

        <div className="flex flex-col w-full gap-2">
          {buttons2.map((button, index) => (
            <Tooltip
              key={index}
              content={button.tooltip}
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                variant="light"
                className="flex justify-start"
                startContent={button.icon}
                onClick={button.onClick}
                isDisabled={button.disabled}
              >
                {button.label}
              </Button>
            </Tooltip>
          ))}
        </div>

        <Divider className="my-4" />

        <div className="flex flex-col w-full gap-2">
          <div>
            <Slider
              aria-label="Quality Level"
              size="md"
              minValue={0}
              maxValue={9}
              label={"Quality Level"}
              step={1}
              value={localStore.qualityLevel}
              onChange={handleQualityLevel}
              color="foreground"
              className="max-w-md w-full px-4"
            />
          </div>
          <div>
            <Slider
              aria-label="Compression Level"
              size="md"
              minValue={0}
              maxValue={9}
              step={1}
              label={"Compression Level"}
              color="foreground"
              value={localStore.compressionLevel}
              onChange={handleCompressionLevel}
              className="max-w-md w-full px-4"
            />
          </div>
        </div>

        <Divider className="my-4" />

        <div className="mt-8">
          <Card className="mx-2 overflow-visible bg-customGray backdrop-blur-custom !shadow-custom">
            <CardBody className="items-center py-5 text-center">
              <h3 className="text-medium font-medium text-default-700">
                Follow Us on X
                <span aria-label="rocket-emoji" className="ml-2" role="img">
                  🚀
                </span>
              </h3>
              <p className="p-4 px-1 text-small text-default-600">
                Stay updated with our latest news and updates by following us on
                X.
              </p>
            </CardBody>
            <CardFooter className="absolute -bottom-8 justify-center">
              <Button
                className="px-10 shadow-md"
                radius="full"
                variant="shadow"
                as="a"
                href="https://x.com/synk_ws"
                target="_blank"
                rel="noopener noreferrer"
              >
                Follow Us
              </Button>
            </CardFooter>
          </Card>
        </div>
      </ScrollShadow>
    </div>
  );
});

export default Sidebar;
