import React from "react";
import { Highlight } from "../../Highlight/Highlight";
import { RedHighlight } from "../../RedHighlight/RedHighlight";

type AnswerProps = {
  content: string;
};

const Answer = ({ content }: AnswerProps) => {
  const renderContent = () => {
    const parts = content.split(/(\*{2,4}.*?\*{2,4})/);

    return parts.map((part, i) => {
      if (part.match(/^\*{4}(.*)\*{4}$/)) {
        const inner = part.replace(/^\*{4}(.*)\*{4}$/, "$1");
        return <RedHighlight key={i}>{inner}</RedHighlight>;
      } else if (part.match(/^\*{2}(.*)\*{2}$/)) {
        const inner = part.replace(/^\*{2}(.*)\*{2}$/, "$1");
        return <Highlight key={i}>{inner}</Highlight>;
      } else {
        const lines = part.split("\n");
        return lines.map((line, j) => (
          <React.Fragment key={`${i}-${j}`}>
            {line}
            {j < lines.length - 1 && <br />}
          </React.Fragment>
        ));
      }
    });
  };

  return (
    <div className="flex max-w-[1150px] gap-3 w-full justify-center items-center lg:items-start transition-width duration-500 flex-col lg:flex-row">
      <div className="flex flex-col justify-start max-w-[750px] w-full">
        <div className="pt-6 pb-2 flex justify-between items-center w-full ">
          <div className="flex justify-start items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
              ></path>
            </svg>
            <div className="font-bold pt-1 text-xl text-transparent bg-clip-text -tracking-tight bg-gradient-to-br from-gray-100 to-slate-700">
              Answer
            </div>
          </div>
          <div></div>
        </div>
        <article className="max-w-[750px] w-full flex flex-col justify-center p-3 border border-gray-900 bg-customGray/90 rounded-2xl relative">
          <div className="absolute inset-0 bg-black/[0.60] rounded-2xl z-0"></div>
          <div className="prose break-words dark:prose-invert pt-1 w-full prose-gray text-base prose-p:leading-relaxed prose-pre:p-0 overflow-hidden z-[1]">
            {renderContent()}
          </div>
        </article>
      </div>
    </div>
  );
};

export default Answer;
