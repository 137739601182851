import React, { useRef, useEffect, useCallback, useState } from "react";
import RFB from "@countersink/s_novnc";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { NoVncLocalStore } from "./store/NoVncLocalStore";
import { Skeleton, Button, Modal, Input, Slider } from "@nextui-org/react";
import { Drawer } from "vaul";
import DrawerContent from "./components/DrawerContent";
import appStore from "stores/AppStore";
import { motion } from "framer-motion";
import clsx from "clsx";
import {
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import { Sun } from "lucide-react";

interface NoVNCViewerProps extends React.HTMLAttributes<HTMLDivElement> {
  url: string;
  localId: string;
  localVncStore: NoVncLocalStore;
  onBringToFront?: () => void;
}

interface ExtendedRFB extends RFB {
  _qualityLevel: number;
  _compressionLevel: number;
  _sendEncodings: () => void;
}

const isMobileDevice = () => {
  if (typeof navigator !== "undefined") {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
  return false;
};

const NoVNCViewer: React.FC<NoVNCViewerProps> = observer(
  ({
    url,
    localId,
    localVncStore,
    className,
    onBringToFront,
    style,
    ...rest
  }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const rfbRef = useRef<RFB | null>(null);
    const isAttemptingConnectionRef = useRef(false);

    const autoAttemptIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const manualAttemptIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const autoReconnectionAttemptsRef = useRef(0);
    const manualReconnectionAttemptsRef = useRef(0);

    const hiddenInputRef = useRef<HTMLInputElement | null>(null);
    const attemptStartTimeRef = useRef<number | null>(null);
    const [isReconnecting, setIsReconnecting] = useState(false);
    const [showReconnectUI, setShowReconnectUI] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

    const lastTapRef = useRef<number | null>(null);
    const TAP_DELAY = 300;

    const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
      const instance = appStore.runningApps.get(localId);

      if (!instance || !instance.shouldCapturePreview) {
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
        return;
      }

      if (!intervalIdRef.current) {
        intervalIdRef.current = setInterval(() => {
          try {
            if (rfbRef.current) {
              const canvas: HTMLCanvasElement | null = (rfbRef.current as any)
                ?._canvas;
              if (canvas) {
                const dataUrl = canvas.toDataURL("image/jpeg", 0.6);
                appStore.setPreviewImage(localId, dataUrl);
              }
            }
          } catch {}
        }, 1000);
      }

      return () => {
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
      };
    }, [localId, appStore.runningApps.get(localId)?.shouldCapturePreview]);

    const connect = useCallback(() => {
      if (containerRef.current && !isAttemptingConnectionRef.current) {
        isAttemptingConnectionRef.current = true;

        if (rfbRef.current) {
          rfbRef.current.disconnect();
          rfbRef.current = null;
        }

        try {
          const rfb = new RFB(containerRef.current, url, {
            qualityLevel: localVncStore.qualityLevel,
            compressionLevel: localVncStore.compressionLevel,
          });

          rfb.addEventListener("clipboard", (event: any) => {
            const serverText = event.detail?.text || event.detail || "";
            if (serverText) {
              navigator.clipboard
                .writeText(serverText)
                .catch((err) => console.error("Error", err));
            }
          });

          const isMobile = isMobileDevice();

          rfb.scaleViewport = true;
          rfb.resizeSession = true;
          rfb.clipViewport = false;
          rfb.dragViewport = false;
          rfb.keyboardEnabled = true;
          rfb.focusOnClick = !isMobile;

          const handleConnect = () => {
            runInAction(() => {
              localVncStore.setConnected(true);
            });

            setIsReconnecting(false);
            setShowReconnectUI(false);

            isAttemptingConnectionRef.current = false;

            if (autoAttemptIntervalRef.current) {
              clearInterval(autoAttemptIntervalRef.current);
              autoAttemptIntervalRef.current = null;
              autoReconnectionAttemptsRef.current = 0;
            }

            if (manualAttemptIntervalRef.current) {
              clearInterval(manualAttemptIntervalRef.current);
              manualAttemptIntervalRef.current = null;
              manualReconnectionAttemptsRef.current = 0;
            }

            localVncStore.stopLoadingProgress();

            const start = Math.floor(localVncStore.loadingProgress);
            const end = 100;
            const duration = localVncStore.delayAfterConnection;
            const startTime = Date.now();

            const animateProgress = () => {
              const elapsed = Date.now() - startTime;
              if (elapsed < duration) {
                const progress = start + ((end - start) * elapsed) / duration;
                runInAction(() => {
                  localVncStore.setLoadingProgress(progress);
                });
                requestAnimationFrame(animateProgress);
              } else {
                runInAction(() => {
                  localVncStore.setLoadingProgress(100);
                  localVncStore.setLoading(false);
                });
              }
            };

            animateProgress();
            setTimeout(() => {
              appStore.setInstanceConnected(localId);
            }, 2000);
          };

          const handleDisconnect = () => {
            runInAction(() => {
              localVncStore.setConnected(false);
            });

            isAttemptingConnectionRef.current = false;
            startReconnectionAttempts();
          };

          const handleError = (e: any) => {
            isAttemptingConnectionRef.current = false;
            setIsReconnecting(false);
            setShowReconnectUI(true);
          };

          rfb.addEventListener("connect", handleConnect);
          rfb.addEventListener("disconnect", handleDisconnect);
          rfb.addEventListener("securityfailure", (e: any) => {
            isAttemptingConnectionRef.current = false;
          });
          rfb.addEventListener("error", handleError);

          rfbRef.current = rfb;

          const canvas = (rfbRef.current as any)._canvas as HTMLCanvasElement;
          if (canvas) {
            canvas.addEventListener("touchend", handleCanvasTouchEnd);
            canvasRef.current = canvas;
          }

          localVncStore.sendClipboardText = (text: string) => {
            rfb.clipboardPasteFrom(text);
          };
        } catch (error) {
          isAttemptingConnectionRef.current = false;
          setIsReconnecting(false);
          setShowReconnectUI(true);
        }
      }
    }, [url, localVncStore]);

    const startConnectionAttempts = useCallback(() => {
      attemptStartTimeRef.current = Date.now();
      connect();
      autoAttemptIntervalRef.current = setInterval(() => {
        if (!localVncStore.connected && !isAttemptingConnectionRef.current) {
          const elapsed =
            Date.now() - (attemptStartTimeRef.current || Date.now());
          if (elapsed >= localVncStore.MAX_ATTEMPT_DURATION) {
            if (autoAttemptIntervalRef.current) {
              clearInterval(autoAttemptIntervalRef.current);
              autoAttemptIntervalRef.current = null;
            }
            runInAction(() => {
              localVncStore.remainingTime = 0;
              localVncStore.isLoading = false;
            });
          } else {
            connect();
            runInAction(() => {
              if (localVncStore.loadingProgress < 90) {
                localVncStore.incrementProgress(2);
              }
            });
          }
        }
      }, 1000);
    }, [connect, localVncStore]);

    const attemptReconnect = (isManual: boolean) => {
      const attemptsRef = isManual
        ? manualReconnectionAttemptsRef
        : autoReconnectionAttemptsRef;
      attemptsRef.current += 1;
      connect();
    };

    const startReconnectionAttempts = useCallback(
      (isManual = false) => {
        const attemptsRef = isManual
          ? manualReconnectionAttemptsRef
          : autoReconnectionAttemptsRef;
        const intervalRef = isManual
          ? manualAttemptIntervalRef
          : autoAttemptIntervalRef;

        if (intervalRef.current !== null) {
          return;
        }

        attemptsRef.current = 0;

        setIsReconnecting(true);

        attemptReconnect(isManual);

        intervalRef.current = setInterval(() => {
          if (!localVncStore.connected && !isAttemptingConnectionRef.current) {
            if (attemptsRef.current < 2) {
              attemptReconnect(isManual);
            } else {
              if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
              }
              setIsReconnecting(false);
              setShowReconnectUI(true);
            }
          }
        }, 500);
      },
      [attemptReconnect, localVncStore.connected]
    );

    useEffect(() => {
      if (rfbRef.current) {
        const rfb = rfbRef.current as ExtendedRFB;
        rfb._qualityLevel = localVncStore.qualityLevel;
        rfb._compressionLevel = localVncStore.compressionLevel;
        rfb._sendEncodings();
      }
    }, [localVncStore.qualityLevel, localVncStore.compressionLevel]);

    useEffect(() => {
      startConnectionAttempts();
      return () => {
        if (rfbRef.current) {
          if (canvasRef.current) {
            canvasRef.current.removeEventListener(
              "touchend",
              handleCanvasTouchEnd
            );
          }
          rfbRef.current.disconnect();
          rfbRef.current = null;
        }
        if (autoAttemptIntervalRef.current) {
          clearInterval(autoAttemptIntervalRef.current);
          autoAttemptIntervalRef.current = null;
        }
        if (manualAttemptIntervalRef.current) {
          clearInterval(manualAttemptIntervalRef.current);
          manualAttemptIntervalRef.current = null;
        }
      };
    }, [url, startConnectionAttempts]);

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === "visible") {
          if (
            !localVncStore.connected &&
            !isAttemptingConnectionRef.current &&
            autoAttemptIntervalRef.current === null
          ) {
            if (!showReconnectUI) {
              startReconnectionAttempts();
            }
          }
        } else {
          if (rfbRef.current) {
            rfbRef.current.disconnect();
            rfbRef.current = null;
          }
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);
      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }, [showReconnectUI, startReconnectionAttempts, localVncStore]);

    const handleReconnect = () => {
      if (
        localVncStore.remainingTime !== null &&
        localVncStore.remainingTime > 0
      ) {
        if (manualAttemptIntervalRef.current) {
          clearInterval(manualAttemptIntervalRef.current);
          manualAttemptIntervalRef.current = null;
        }
        setShowReconnectUI(false);
        startReconnectionAttempts(true);
      }
    };

    const keySymMap: { [key: string]: number } = {
      Backspace: 0xff08,
      Tab: 0xff09,
      Enter: 0xff0d,
      Shift: 0xffe1,
      Control: 0xffe3,
      Alt: 0xffe9,
      Pause: 0xff13,
      CapsLock: 0xffe5,
      Escape: 0xff1b,
      PageUp: 0xff55,
      PageDown: 0xff56,
      End: 0xff57,
      Home: 0xff50,
      ArrowLeft: 0xff51,
      ArrowUp: 0xff52,
      ArrowRight: 0xff53,
      ArrowDown: 0xff54,
      Insert: 0xff63,
      Delete: 0xffff,
    };

    const handleCanvasTouchEnd = (e: TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const now = Date.now();
      if (lastTapRef.current && now - lastTapRef.current < TAP_DELAY) {
        if (hiddenInputRef.current) {
          hiddenInputRef.current.focus();
        }
        lastTapRef.current = null;
      } else {
        lastTapRef.current = now;
      }
    };

    const handleInputFocus = () => {
      setIsKeyboardOpen(true);
    };

    const handleInputBlur = () => {
      setIsKeyboardOpen(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (rfbRef.current) {
        const key = e.key;
        const keysym = keySymMap[key] || key.charCodeAt(0);
        rfbRef.current.sendKey(keysym, key, true);
        e.preventDefault();
      }
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (rfbRef.current) {
        const key = e.key;
        const keysym = keySymMap[key] || key.charCodeAt(0);
        rfbRef.current.sendKey(keysym, key, false);
        e.preventDefault();
      }
    };

    return (
      <div
        ref={containerRef}
        className={clsx(
          "relative w-full h-full overflow-hidden rounded-2xl border-none",
          className
        )}
        style={style}
        {...rest}
        onPointerDownCapture={() => {
          onBringToFront?.();
        }}
      >
        {localVncStore.connected && (
          <Drawer.Root
            open={localVncStore.isDrawerOpen}
            onOpenChange={localVncStore.toggleDrawer}
            direction="left"
          >
            <Drawer.Trigger asChild>
              <motion.div
                data-testid="quick-access-menu"
                className="absolute left-0 top-1/2 transform -translate-y-1/2 h-[80px] w-5 sm:w-4 z-50 cursor-pointer rounded-full rounded-l-lg flex items-center justify-center before:absolute before:inset-0 before:-z-10 before:bg-customGray/60 before:backdrop-filter before:backdrop-blur-[40px] before:backdrop-saturate-200 before:rounded-full before:rounded-l-lg"
              >
                <div className="w-1 h-12 rounded-full bg-white bg-opacity-80 backdrop-blur-sm"></div>
              </motion.div>
            </Drawer.Trigger>
            <Drawer.Overlay className="absolute inset-0 z-40 bg-black bg-opacity-50 rounded-2xl" />
            <Drawer.Content
              className={clsx(
                "bg-[radial-gradient(circle_at_26%_0,rgba(178,198,255,0.15),rgba(243,243,244,0.03)_33%,rgba(255,255,255,0)_56%,rgba(57,59,76,0.07))]",
                "before:absolute before:inset-0 before:-z-10 before:bg-customGray/30 before:backdrop-filter before:backdrop-blur-[40px] before:backdrop-saturate-200",
                "flex flex-col rounded-t-[10px] h-full w-[270px] absolute bottom-0 left-0 z-50"
              )}
            >
              <div className="p-4 flex-1 h-full">
                <DrawerContent localStore={localVncStore} />
              </div>
            </Drawer.Content>
          </Drawer.Root>
        )}

        <input
          ref={hiddenInputRef}
          type="text"
          style={{
            position: "absolute",
            opacity: 0,
            height: "1px",
            width: "1px",
            border: "none",
            outline: "none",
            zIndex: -1,
          }}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />

        {isReconnecting && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
            <Skeleton className="w-full h-full" />
          </div>
        )}

        {showReconnectUI && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
            <div className="text-center text-white">
              <p className="font-bold bg-gray-100/[0.1] text-white-500 px-4 py-1.5 rounded-md">
                Oops, you've lost the connection.
              </p>
              {localVncStore.remainingTime !== null &&
              localVncStore.remainingTime > 0 ? (
                <Button
                  onClick={handleReconnect}
                  className="mt-2 bg-white text-black"
                  isLoading={isReconnecting}
                  radius="md"
                >
                  Reconnect
                </Button>
              ) : (
                <p>Your session has expired.</p>
              )}
            </div>
          </div>
        )}

        <Modal
          closeButton
          backdrop="transparent"
          isOpen={localVncStore.isClipboardModalOpen}
          onClose={() => localVncStore.setClipboardModalOpen(false)}
          portalContainer={containerRef.current ?? undefined}
          classNames={{
            body: "py-6",
            wrapper: "w-full !h-[100%]",
            base: "border-[#292f46] bg-customGray backdrop-blur-dock text-[#a8b0d3]",
            header: "border-b-[1px] border-[#292f46]",
            footer: "border-t-[1px] border-[#292f46]",
            closeButton: "hover:bg-white/5 active:bg-white/10",
          }}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader>
                  <h3>Clipboard</h3>
                </ModalHeader>
                <ModalBody>
                  <Input
                    label="Text"
                    type="textarea"
                    placeholder="Enter text to send to your instance"
                    onValueChange={(val) => localVncStore.setClipboardText(val)}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => localVncStore.setClipboardModalOpen(false)}
                  >
                    Close
                  </Button>
                  <Button
                    color="success"
                    onClick={() => {
                      localVncStore.sendClipboardText(
                        localVncStore.clipboardText
                      );
                      localVncStore.setClipboardModalOpen(false);
                      localVncStore.setClipboardText("");
                    }}
                  >
                    Send
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>

        <Modal
          closeButton
          backdrop="transparent"
          isOpen={localVncStore.isBrightnessModalOpen}
          onClose={() => localVncStore.setBrightnessModalOpen(false)}
          portalContainer={containerRef.current ?? undefined}
          classNames={{
            body: "py-6",
            wrapper: "w-full !h-[100%]",
            base: "border-[#292f46] bg-customGray backdrop-blur-dock text-[#a8b0d3]",
            header: "border-b-[1px] border-[#292f46]",
            footer: "border-t-[1px] border-[#292f46]",
            closeButton: "hover:bg-white/5 active:bg-white/10",
          }}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader>
                  <h3>Brightness Control</h3>
                </ModalHeader>
                <ModalBody>
                  <div className="flex flex-col gap-4 w-full items-center justify-center">
                    <Slider
                      aria-label="Brightness"
                      size="lg"
                      color="success"
                      step={0.1}
                      minValue={0.5}
                      maxValue={1.5}
                      value={localVncStore.brightness}
                      onChange={(val) => {
                        if (typeof val === "number") {
                          localVncStore.setBrightness(val);
                        }
                      }}
                      startContent={<Sun className="text-2xl" size={20} />}
                      endContent={<Sun className="text-2xl" size={26} />}
                      className="max-w-md w-full"
                    />
                    <p className="text-default-500 font-medium text-small">
                      Current brightness: {localVncStore.brightness.toFixed(1)}
                    </p>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => localVncStore.setBrightnessModalOpen(false)}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
    );
  }
);

export default NoVNCViewer;
