import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "@nextui-org/react";

import ProfileTab from "./components/ProfileTab/ProfileTab";
import VPNTab from "./components/VPNTab/VPNTab";
import AppearanceTab from "./components/AppearanceTab/AppearanceTab";

import SettingsLocalStore from "./store/SettingsLocalStore";

interface SettingsProps {
  initialTab?: string;
  localStore?: SettingsLocalStore;
}

const ProfilePage: React.FC<SettingsProps> = observer(({ localStore }) => {
  const { t } = useTranslation();

  if (!localStore) {
    return (
      <div className="p-4 text-red-500">{t("error_no_store_instance")}</div>
    );
  }

  const selectedTab = localStore.selectedTab;

  const handleTabsChange = (key: React.Key) => {
    localStore.setSelectedTab(key as string);
  };

  return (
    <div className="w-full h-full flex justify-center p-4 @container">
      <div className="w-full max-w-3xl flex-1">
        <div className="flex items-center gap-x-3">
          <h1 className="text-3xl font-bold leading-9 text-default-foreground">
            {t("settings")}
          </h1>
        </div>
        <h2 className="mt-2 text-small text-default-500">
          {t("customize_settings_description")}
        </h2>

        <Tabs
          fullWidth
          selectedKey={selectedTab}
          onSelectionChange={handleTabsChange}
          classNames={{
            base: "mt-6",
            cursor: "bg-content1",
            panel: "w-full p-0 pt-4",
          }}
        >
          <Tab key="profile" title={t("profile")}>
            <ProfileTab />
          </Tab>
          <Tab key="vpn" title={t("vpn")}>
            <VPNTab />
          </Tab>
          <Tab key="appearance" title={t("appearance")}>
            <AppearanceTab />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
});

export default ProfilePage;
