import React from "react";
import { IndeterminateProgress } from "components/UI/IndeterminateProgress";
import { cn } from "lib/utils";

interface WindowLoaderProps {
  logoUrl?: string;
  title?: string;
  backgroundColor?: string;
  progressBarColor?: string;
  trackColor?: string;
  className?: string;
}

export const WindowLoader: React.FC<WindowLoaderProps> = ({
  logoUrl,
  title,
  backgroundColor = "#000",
  progressBarColor = "#FFFFFF",
  trackColor = "#666666",
  className,
}) => {
  return (
    <div
      className={cn(
        "flex flex-col gap-6 items-center justify-center w-full h-full rounded-2xl p-6",
        className
      )}
      style={{ backgroundColor }}
    >
      <div className="flex flex-col items-center space-y-4 p-6 rounded-lg">
        {logoUrl && (
          <img
            src={logoUrl}
            alt="Logo"
            className="w-20 h-20 rounded-xl object-contain"
          />
        )}
      </div>

      <IndeterminateProgress
        progressBarColor={progressBarColor}
        trackColor={trackColor}
        className="max-w-[250px]"
      />
    </div>
  );
};
