import { makeAutoObservable, runInAction } from "mobx";
import osintApiClient from "utils/osintApiClient";

interface ChatMessage {
  role: "user" | "assistant" | "system";
  content: string;
}

interface Conversation {
  id: string;
  messages: ChatMessage[];
  title: string;
  updatedAt: number;
}

type ViewState = "setup" | "loading" | "chat" | "error";

const SCRAPE_ENDPOINT = "/scrape";
const SCRAPE_STATUS_ENDPOINT = "/scrape/status";
const CHAT_INIT_ENDPOINT = "/chat/init";
const CHAT_STREAM_ENDPOINT = "/chat/stream";

const POLL_INTERVAL_MS = 3000;
const PROGRESS_INTERVAL_MS = 500;
const MAX_PROGRESS = 95;
const FINAL_PROGRESS = 100;
const ESTIMATED_TIME_MS = 45000; // 45 s
const TIMEOUT_MS = 120000; // 2 min

export class OsynkLocalStore {
  instanceId: string;
  conversations: Conversation[] = [];
  currentConversationId: string | null = null;
  loading = false;
  error: string | null = null;
  viewState: ViewState = "setup";
  scrapeJobId: string | null = null;
  progress = 0;
  private progressInterval: NodeJS.Timeout | null = null;
  private timeoutId: NodeJS.Timeout | null = null;
  selectedCategory: string | null = null;
  searchValue: string | null = null;
  scrapeFinished = false;
  chatInitReceived = false;
  draftMessage: string = "";
  selectedSetupCategory: string = "email";
  setupInputValue: string = "";

  constructor(instanceId: string) {
    makeAutoObservable(this);
    this.instanceId = instanceId;

    this.loadFromLocalStorage();

    if (this.conversations.length === 0) {
      this.startNewConversation();
    } else {
      this.currentConversationId = this.conversations[0].id;
    }
  }

  setDraftMessage(value: string) {
    this.draftMessage = value;
  }

  get currentConversation(): Conversation | null {
    if (!this.currentConversationId) return null;
    return (
      this.conversations.find((c) => c.id === this.currentConversationId) ||
      null
    );
  }

  get messages(): ChatMessage[] {
    return this.currentConversation?.messages || [];
  }

  loadFromLocalStorage() {
    const data = localStorage.getItem(`chat_conversations_${this.instanceId}`);
    if (data) {
      this.conversations = JSON.parse(data) as Conversation[];
    }
  }

  saveToLocalStorage() {
    localStorage.setItem(
      `chat_conversations_${this.instanceId}`,
      JSON.stringify(this.conversations)
    );
  }

  setSelectedSetupCategory(category: string) {
    this.selectedSetupCategory = category;
  }

  setSetupInputValue(value: string) {
    this.setupInputValue = value;
  }

  startNewConversation() {
    const newId = crypto.randomUUID();
    const newConv: Conversation = {
      id: newId,
      messages: [],
      title: "New Conversation",
      updatedAt: Date.now(),
    };
    this.conversations.unshift(newConv);
    this.currentConversationId = newId;
    this.saveToLocalStorage();
  }

  updateConversationTitle() {
    const conv = this.currentConversation;
    if (!conv) return;
    const firstUserMsg = conv.messages.find((m) => m.role === "user");
    conv.title = firstUserMsg
      ? firstUserMsg.content.slice(0, 30)
      : "Untitled Conversation";
  }

  updateConversationTimestamp() {
    const conv = this.currentConversation;
    if (!conv) return;
    conv.updatedAt = Date.now();
  }

  addUserMessage(content: string) {
    const conv = this.currentConversation;
    if (!conv) return;
    conv.messages.push({ role: "user", content });
    this.updateConversationTitle();
    this.updateConversationTimestamp();
    this.saveToLocalStorage();
  }

  addAssistantMessage(content: string) {
    const conv = this.currentConversation;
    if (!conv) return;
    conv.messages.push({ role: "assistant", content });
    this.updateConversationTimestamp();
    this.saveToLocalStorage();
  }

  clearMessages() {
    this.startNewConversation();
    this.loading = false;
    this.error = null;
  }

  setViewState(state: ViewState) {
    this.viewState = state;
  }

  async startScraping(category: string, value: string) {
    try {
      this.setViewState("loading");
      this.progress = 0;
      this.scrapeFinished = false;
      this.chatInitReceived = false;
      this.startProgressIncrement();

      this.selectedCategory = category;
      this.searchValue = value;

      this.timeoutId = setTimeout(() => {
        runInAction(() => {
          this.error = "Timeout: The process took too long (2 minutes).";
          this.stopProgressIncrement();
          this.setViewState("error");
        });
      }, TIMEOUT_MS);

      const response = await osintApiClient.post(SCRAPE_ENDPOINT, {
        category,
        value,
      });
      runInAction(() => {
        if (response.data.status !== "progress") {
          this.stopProgressIncrement();
          this.clearTimeout();
          this.error = "Unexpected status";
          this.setViewState("error");
          return;
        }
        this.scrapeJobId = response.data.job_id;
      });
      this.pollScrapeStatus();
    } catch (err: any) {
      this.stopProgressIncrement();
      this.clearTimeout();
      runInAction(() => {
        this.error = err.message;
        this.setViewState("error");
      });
    }
  }

  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  startProgressIncrement() {
    this.stopProgressIncrement();
    this.progress = 0;
    this.progressInterval = setInterval(() => {
      runInAction(() => {
        if (this.viewState === "loading" && this.progress < MAX_PROGRESS) {
          const increment = Math.floor(Math.random() * 2) + 1; // entre 1 et 2
          this.progress += increment;
          if (this.progress > MAX_PROGRESS) {
            this.progress = MAX_PROGRESS;
          }
        }
      });
    }, PROGRESS_INTERVAL_MS);
  }

  stopProgressIncrement() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
    }
  }

  async pollScrapeStatus() {
    if (!this.scrapeJobId) return;

    const interval = setInterval(async () => {
      try {
        const response = await osintApiClient.get(
          `${SCRAPE_STATUS_ENDPOINT}?job_id=${this.scrapeJobId}`
        );
        const data = response.data;
        runInAction(async () => {
          if (data.status === "finished") {
            this.scrapeFinished = true;
            clearInterval(interval);

            try {
              const initRes = await osintApiClient.get(
                `${CHAT_INIT_ENDPOINT}?job_id=${this.scrapeJobId}`
              );
              if (initRes.status !== 200) {
                const errData = initRes.data || {};
                throw new Error(
                  "Failed to get initial summary: " +
                    (errData.detail || "Unknown error")
                );
              }
              const initData = initRes.data;
              const summaryMsg = initData.assistant_message;

              const osintRes = await osintApiClient.get(
                `${SCRAPE_STATUS_ENDPOINT}?job_id=${this.scrapeJobId}`
              );
              const osintData = osintRes.data;

              runInAction(() => {
                this.clearMessages();
                this.currentConversation!.messages.push({
                  role: "system",
                  content:
                    "OSINT DATA (DO NOT REVEAL):\n" +
                    JSON.stringify(osintData, null, 2),
                });

                this.addAssistantMessage(summaryMsg);
                this.chatInitReceived = true;

                if (this.scrapeFinished && this.chatInitReceived) {
                  this.stopProgressIncrement();
                  this.progress = FINAL_PROGRESS;

                  setTimeout(() => {
                    runInAction(() => {
                      this.clearTimeout();
                      this.setViewState("chat");
                      this.scrapeJobId = null;
                    });
                  }, 1000);
                }
              });
            } catch (e: any) {
              runInAction(() => {
                this.stopProgressIncrement();
                this.clearTimeout();
                this.error = e.message || "Failed to initialize chat";
                this.setViewState("error");
              });
            }
          }
        });
      } catch (e: any) {
        runInAction(() => {
          this.error = e.message || "Unknown error polling scrape status";
          this.stopProgressIncrement();
          this.clearTimeout();
          this.setViewState("error");
        });
        clearInterval(interval);
      }
    }, POLL_INTERVAL_MS);
  }

  async sendMessageStream(content: string) {
    if (!content.trim()) return;
    this.addUserMessage(content);

    this.loading = true;
    this.error = null;

    const body = { messages: this.messages };

    const baseUrl =
      process.env.REACT_APP_API_URL_OSINT || "http://127.0.0.1:8000/api";
    const endpoint = `${baseUrl}${CHAT_STREAM_ENDPOINT}`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        runInAction(() => {
          this.error = errorData.detail || "Server error occurred";
          this.loading = false;
        });
        return;
      }

      runInAction(() => {
        this.addAssistantMessage("");
      });

      const reader = response.body?.getReader();
      if (!reader) {
        runInAction(() => {
          this.loading = false;
          this.error = "No response body";
        });
        return;
      }

      const decoder = new TextDecoder("utf-8");
      let done = false;

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        if (value) {
          const chunk = decoder.decode(value, { stream: true });
          runInAction(() => {
            const conv = this.currentConversation;
            if (
              conv &&
              conv.messages.length > 0 &&
              conv.messages[conv.messages.length - 1].role === "assistant"
            ) {
              conv.messages[conv.messages.length - 1].content += chunk;
              this.updateConversationTimestamp();
              this.saveToLocalStorage();
            }
          });
        }
      }

      runInAction(() => {
        this.loading = false;
      });
    } catch (e: any) {
      runInAction(() => {
        this.loading = false;
        this.error = e.message || "An unknown error occurred";
      });
    }
  }
}
