import React from "react";
import { observer } from "mobx-react-lite";
import { cn } from "lib/utils";
import WindowPreview from "components/Window/WindowPreview";
import { RunningAppInstance } from "stores/AppStore";
import { WindowLoader } from "components/Window/WindowLoader";

function PreviewLoader({
  appDefinition,
}: {
  appDefinition: RunningAppInstance["appDefinition"];
}) {
  const {
    title = "",
    image: appLogoUrl,
    backgroundColor = "#000",
    progressBarColor = "#FFF",
  } = appDefinition;

  return (
    <div className="relative w-full h-full overflow-hidden">
      <div className="absolute inset-0 flex items-center justify-center">
        <div
          style={{
            transform: "scale(0.5)",
            transformOrigin: "center center",
          }}
        >
          <WindowLoader
            title={title}
            logoUrl={appLogoUrl}
            backgroundColor={backgroundColor}
            progressBarColor={progressBarColor}
            className="w-[1900px] h-[1200px]"
          />
        </div>
      </div>
    </div>
  );
}

type LinkPreviewProps = {
  appInstance: RunningAppInstance;
  className?: string;
};

export const LinkPreview = observer<LinkPreviewProps>(function LinkPreviewImpl({
  appInstance,
  className,
}) {
  const { appDefinition, windowSize, previewImage, isLoading } = appInstance;
  const {
    title,
    appName,
    image: logoUrl,
    component,
    requiresBackend,
  } = appDefinition;

  const previewWidth = 250;
  const previewHeight = 150;

  const origW = windowSize.width;
  const origH = windowSize.height;
  const scale = Math.min(previewWidth / origW, previewHeight / origH);

  if (requiresBackend) {
    if (isLoading || !previewImage) {
      return (
        <div
          className={cn(
            "relative rounded-lg overflow-hidden box-border flex items-center justify-center",
            className,
            "w-[250px] h-[150px]"
          )}
        >
          <PreviewLoader appDefinition={appDefinition} />
        </div>
      );
    } else {
      return (
        <div
          className={cn(
            "relative rounded-lg overflow-hidden box-border flex items-center justify-center",
            className,
            "w-[250px] h-[150px]"
          )}
        >
          <img
            src={previewImage}
            alt="preview"
            className="rounded-lg object-contain max-w-full max-h-full"
          />
        </div>
      );
    }
  }

  return (
    <div
      className={cn(
        "relative rounded-lg overflow-hidden box-border flex items-center justify-center",
        className,
        "w-[250px] h-[150px]"
      )}
    >
      <div
        className="transform"
        style={{
          width: origW,
          height: origH,
          transform: `scale(${scale})`,
          pointerEvents: "none",
        }}
      >
        <WindowPreview
          title={title || appName}
          windowSize={{ width: origW, height: origH }}
          isLoading={isLoading}
          appLogoUrl={logoUrl}
          component={component}
          localStore={appInstance.localStore}
        />
      </div>
    </div>
  );
});

export default LinkPreview;
