import React from "react";
import Sidebar from "./Sidebar";
import { NoVncLocalStore } from "../store/NoVncLocalStore";

interface DrawerContentProps {
  localStore: NoVncLocalStore;
}

const DrawerContent: React.FC<DrawerContentProps> = ({ localStore }) => {
  return (
    <div className="p-4 h-full relative">
      <Sidebar localStore={localStore} />
    </div>
  );
};

export default DrawerContent;
