import { makeAutoObservable } from "mobx";

export class SettingsLocalStore {
  instanceId: string;
  selectedTab: string;

  constructor(instanceId: string, initialTab: string = "profile") {
    makeAutoObservable(this);
    this.instanceId = instanceId;
    this.selectedTab = initialTab;
  }

  setSelectedTab(tabKey: string) {
    this.selectedTab = tabKey;
  }
}

export default SettingsLocalStore;
